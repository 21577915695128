import React, {Component} from 'react';
import Link from '@material-ui/core/Link';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';

import Typography from '@material-ui/core/Typography';
import ApiService from './ApiService';
import SearchIcon from '@material-ui/icons/Search';
import PriceCheckIcon from '@material-ui/icons/InfoOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Box from '@material-ui/core/Box';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import MaterialTable, { MTableToolbar } from 'material-table';
import DateFnsUtils from '@date-io/date-fns';
import {subMonths, subDays, format} from 'date-fns';
import { forwardRef } from 'react';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';

import {
  MuiPickersUtilsProvider,
  DatePicker,
  TimePicker
} from '@material-ui/pickers';
import Button from '@material-ui/core/Button';
 


const useStyles = theme => ({
  button: {
    margin: theme.spacing(1),
  },
 table: {
    minWidth: 650,
	maxWidth: 800,
  },
 head: {
    backgroundColor: theme.palette.action.hover,
  },
  tablewraptext: {
	textOverflow: 'ellipsis', 
    overflow: 'hidden', 
    whiteSpace: 'nowrap',
  },
  small: {
    width: theme.spacing(7),
    height: theme.spacing(4),
  },
  medium: {
    width: '45px',
    height: '15px', 
  },
  chipRoot: {
	display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  searchbar: {
	margin: theme.spacing(2),
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  buttonProgress: {
    color: theme.palette.secondary.main,
    position: 'absolute',
	marginTop: 8,
    marginLeft: -65,
  },
   crossError: {
    color: theme.palette.error.main,
  },
  formControl: { 
    marginLeft: 20,
    minWidth: 120,
    maxWidth: 300,
  },
  formControlList: { 
    minWidth: 150,
    maxWidth: 300,
  },
  menuText: {
  	fontWeight: theme.typography.fontWeightMedium,
  },
});

class MerchantTransactionSummaryComponent extends Component {
	
	constructor(props) {
        super(props)
        this.state = {            
			result: null,
			resultByCurrency: null,
            message: null,
			fromDate: subDays(new Date(),20),
			fromTime: (new Date()).setHours(0,0,0),
			toDate: new Date(),
			toTime: (new Date()).setHours(23,59,59),
			refundFromDate: subDays(new Date(),20),
			refundToDate: new Date(),
			refundFromTime: (new Date()).setHours(0,0,0),
			refundToTime: (new Date()).setHours(23,59,59),
			chbkFromDate: subDays(new Date(),180),
			chbkToDate: new Date(),
			chbkFromTime: (new Date()).setHours(0,0,0),
			chbkToTime: (new Date()).setHours(23,59,59),
			loading: false,
			reconLoading: false,
			merchantTerminals: null,
			terminalId: null,
			failed: false,
			acquirerAccounts: [],
			acquirerAccountId: [],
			accountPoolIdList:[],
			accountPoolId: null,
			critChanged: false,
			success: false,
			canModify: false,
        }
       this.reloadTransactions = this.reloadTransactions.bind(this); 
	   this.handleChange = this.handleChange.bind(this); 
       this.handleStatusSelectChange = this.handleStatusSelectChange.bind(this); 
       this.handleOrderNoChange = this.handleOrderNoChange.bind(this);
       this.handleEmailChange = this.handleEmailChange.bind(this);
	   this.searchTransactions = this.searchTransactions.bind(this);
	   this.fetchAcquirerAccounts = this.fetchAcquirerAccounts.bind(this);
	   this.handleTerminalIdChange = this.handleTerminalIdChange.bind(this);
	   this.handleAcquirerAccountChange = this.handleAcquirerAccountChange.bind(this);
	   this.markDeclinedSettled = this.markDeclinedSettled.bind(this);
	   this.fetchAcquirerAccountsWithPool = this.fetchAcquirerAccountsWithPool.bind(this);
	   this.handleAccountPoolChange = this.handleAccountPoolChange.bind(this);
	   this.fetchUserAccess = this.fetchUserAccess.bind(this);
	   this.markSuccessSettled = this.markSuccessSettled.bind(this);
	   this.reconTransactions = this.reconTransactions.bind(this);
	   this.markRRSettled = this.markRRSettled.bind(this);
    }
	componentDidMount() {
        {/* this.reloadTransactions();*/}
		this.fetchMerchantTerminals();
		this.fetchAcquirerAccounts();
		this.fetchUserAccess();
    }
	handleChange(event) {
        this.setState(
            {
                [event.target.name]
                    : event.target.value, critChanged: true,
            }
        )
    }

	

	handleTerminalIdChange(event) {
		this.handleChange(event);
		this.setState({acquirerAccountId: [], accountPoolId: null});
		this.state.acquirerAccountId = [];
	}
	handleAcquirerAccountChange(event) {
		//this.handleChange(event);
		const { options } = event.target;
		const value = [];
		for (let i = 0, l = options.length; i < l; i += 1) {
			if (options[i].selected) {
				value.push(options[i].value);
			}
		}
		this.setState( { acquirerAccountId : value, critChanged: true});

		this.setState({terminalId: null});
		this.state.terminalId = null;
	}
	handleAccountPoolChange(event) {
		this.setState({accountPoolId: event.target.value, terminalId: null});
		this.fetchAcquirerAccountsWithPool(event.target.value);
	}

	fetchAcquirerAccountsWithPool(accountPoolId) {
		ApiService.fetchAcquirerAccountsWithPoolId(accountPoolId)
			.then((res)=> {
				this.setState({acquirerAccounts: res.data})	
			}).catch((e)=> {
				
			});
	}
	fetchAcquirerAccounts() {
		ApiService.fetchAcquirerAccounts()
			.then((res)=> {
				this.setState({acquirerAccounts: res.data})	
				const poolIdArray = [];
				res.data.forEach((x) => {
					if(x.accountPoolId != null && ! poolIdArray.includes(x.accountPoolId)) 
					poolIdArray.push(x.accountPoolId)
				});
				this.setState({accountPoolIdList: poolIdArray});
			}).catch((e)=> {
				
			});
	}
	
	fetchUserAccess() {
		ApiService.checkAccessByMenuAndAction('Txn Summary', 'AMEND').then((res) => {
			if(res && res.data != null && res.data.id != null) {
				this.setState({canModify: true});
			}
		})
		
	}
	
	reloadTransactions() { 
		this.setState({loading: true});
		if(this.state.acquirerAccountId.length > 0) {
		   ApiService.fetchMerchantTransactionSummaryByAcquirerAccount(format(this.state.fromDate,'yyyy-MM-dd'), format(this.state.fromTime,'HH:mm:ss'), 
				format(this.state.toDate,'yyyy-MM-dd'), format(this.state.toTime,'HH:mm:ss'),
				format(this.state.refundFromDate,'yyyy-MM-dd'), format(this.state.refundFromTime,'HH:mm:ss'),
				format(this.state.refundToDate,'yyyy-MM-dd'), format(this.state.refundToTime,'HH:mm:ss'),
				format(this.state.chbkFromDate,'yyyy-MM-dd'), format(this.state.chbkFromTime,'HH:mm:ss'),
				format(this.state.chbkToDate,'yyyy-MM-dd'), format(this.state.chbkToTime,'HH:mm:ss'),
				this.state.acquirerAccountId, this.state.accountPoolId)
            .then((res) => {
                this.setState({resultByCurrency: res.data,loading: false})
            }).catch((e) => {
				
			});
		} else {
        ApiService.fetchMerchantTransactionSummary(format(this.state.fromDate,'yyyy-MM-dd'),
				format(this.state.fromTime,'HH:mm:ss'), format(this.state.toDate,'yyyy-MM-dd'), 
				format(this.state.toTime,'HH:mm:ss'), 
				format(this.state.refundFromDate,'yyyy-MM-dd'), format(this.state.refundFromTime,'HH:mm:ss'),
				format(this.state.refundToDate,'yyyy-MM-dd'), format(this.state.refundToTime,'HH:mm:ss'),
				format(this.state.chbkFromDate,'yyyy-MM-dd'), format(this.state.chbkFromTime,'HH:mm:ss'),
				format(this.state.chbkToDate,'yyyy-MM-dd'), format(this.state.chbkToTime,'HH:mm:ss'),
				this.state.terminalId)
            .then((res) => {
                this.setState({result: res.data,loading: false})
            }).catch((e) => {
				
			});
		}
		this.setState({critChanged: false});
    }

	searchTransactions() {
		if(this.state.terminalId == null && this.state.acquirerAccountId.length < 1) {
			this.setState({responsemessage:"Please select Merchant Terminal",failed: true});
			return;
		}
		this.setState({results: []});
		this.reloadTransactions();
	}
	reconTransactions() {
		if(this.state.acquirerAccountId.length < 1 || this.state.acquirerAccountId.length > 1) {
			this.setState({responsemessage:"Please select only 1 acquirer account ID for recon.",failed: true});
			return;
		}
		this.setState({reconLoading: true});
		ApiService.reconTransactions(format(this.state.fromDate,'yyyy-MM-dd'),
				format(this.state.fromTime,'HH:mm:ss'), format(this.state.toDate,'yyyy-MM-dd'), 
				format(this.state.toTime,'HH:mm:ss'), this.state.acquirerAccountId).then((res) => {
				this.setState({responsemessage: res.data.responseMessage});
                this.setState({reconLoading: false,failed: false,success: true});
            }).catch((e) => {
				 this.setState({reconLoading: false,failed: true, success: false, responsemessage: e})
			});
	} 
	
 	handleStatusSelectChange(event) {
 		this.setState({statusCriteria: event.target.value})
 	}
 	handleOrderNoChange(event) {
 		this.setState({orderNoCriteria: event.target.value})
 	}
 	handleEmailChange(event) {
 		this.setState({email: event.target.value})
 	}
	fetchMerchantTerminals(){
		ApiService.fetchMerchantTerminals()
			.then((res)=> {
				this.setState({merchantTerminals: res.data})			
			}).catch((e)=> {
				
			});
	}
	markSuccessSettled() {
		if(this.state.critChanged) {
			this.setState({failed: true, responsemessage: "Search criteria has changed, please search again and then try to mark settle"});
			return;
		}

		if(this.state.acquirerAccountId.length > 0) {
			this.setState({loading: true});
		   ApiService.markSuccessSettledByAcquirer(format(this.state.fromDate,'yyyy-MM-dd'), format(this.state.fromTime,'HH:mm:ss'), 
				format(this.state.toDate,'yyyy-MM-dd'), format(this.state.toTime,'HH:mm:ss'),
				format(this.state.refundFromDate,'yyyy-MM-dd'), format(this.state.refundFromTime,'HH:mm:ss'),
				format(this.state.refundToDate,'yyyy-MM-dd'), format(this.state.refundToTime,'HH:mm:ss'),
				format(this.state.chbkFromDate,'yyyy-MM-dd'), format(this.state.chbkFromTime,'HH:mm:ss'),
				format(this.state.chbkToDate,'yyyy-MM-dd'), format(this.state.chbkToTime,'HH:mm:ss'),
				this.state.acquirerAccountId,  this.state.accountPoolId)
            .then((res) => { 
				this.setState({resultByCurrency: res.data,loading: false}) 
            }).catch((e) => {
				 this.setState({loading: false,failed: true, success: false, responsemessage: e})
			});
		} 
	}
	markDeclinedSettled() {
		if(this.state.critChanged) {
			this.setState({failed: true, responsemessage: "Search criteria has changed, please search again and then try to mark settle"});
			return;
		}
		this.setState({loading: true});

		if(this.state.acquirerAccountId.length > 0) {
		   ApiService.markDeclinedSettledByAcquirer(format(this.state.fromDate,'yyyy-MM-dd'), format(this.state.fromTime,'HH:mm:ss'), 
				format(this.state.toDate,'yyyy-MM-dd'), format(this.state.toTime,'HH:mm:ss'),
				format(this.state.refundFromDate,'yyyy-MM-dd'), format(this.state.refundFromTime,'HH:mm:ss'),
				format(this.state.refundToDate,'yyyy-MM-dd'), format(this.state.refundToTime,'HH:mm:ss'),
				format(this.state.chbkFromDate,'yyyy-MM-dd'), format(this.state.chbkFromTime,'HH:mm:ss'),
				format(this.state.chbkToDate,'yyyy-MM-dd'), format(this.state.chbkToTime,'HH:mm:ss'),
				this.state.acquirerAccountId,  this.state.accountPoolId)
            .then((res) => { 
				this.setState({resultByCurrency: res.data,loading: false}) 
            }).catch((e) => {
				 this.setState({loading: false,failed: true, success: false, responsemessage: e})
			});
		} else {
        ApiService.markDeclinedSettled(format(this.state.fromDate,'yyyy-MM-dd'),
				format(this.state.fromTime,'HH:mm:ss'), format(this.state.toDate,'yyyy-MM-dd'), 
				format(this.state.toTime,'HH:mm:ss'), 
				format(this.state.refundFromDate,'yyyy-MM-dd'), format(this.state.refundFromTime,'HH:mm:ss'),
				format(this.state.refundToDate,'yyyy-MM-dd'), format(this.state.refundToTime,'HH:mm:ss'),
				format(this.state.chbkFromDate,'yyyy-MM-dd'), format(this.state.chbkFromTime,'HH:mm:ss'),
				format(this.state.chbkToDate,'yyyy-MM-dd'), format(this.state.chbkToTime,'HH:mm:ss'),
				this.state.terminalId)
            .then((res) => {
	            this.setState({result: res.data,loading: false}) 
            }).catch((e) => {
				 this.setState({loading: false,failed: true, success: false, responsemessage: e})
			});
		}
		
		
		
	}
	markRRSettled() {
		if(this.state.critChanged) {
			this.setState({failed: true, responsemessage: "Search criteria has changed, please search again and then try to mark settle"});
			return;
		}			
		
		if(this.state.acquirerAccountId.length > 0) {
			this.setState({loading: true});
		   ApiService.markRRSettledByAcquirer(format(this.state.fromDate,'yyyy-MM-dd'), format(this.state.fromTime,'HH:mm:ss'), 
				format(this.state.toDate,'yyyy-MM-dd'), format(this.state.toTime,'HH:mm:ss'),
				format(this.state.refundFromDate,'yyyy-MM-dd'), format(this.state.refundFromTime,'HH:mm:ss'),
				format(this.state.refundToDate,'yyyy-MM-dd'), format(this.state.refundToTime,'HH:mm:ss'),
				format(this.state.chbkFromDate,'yyyy-MM-dd'), format(this.state.chbkFromTime,'HH:mm:ss'),
				format(this.state.chbkToDate,'yyyy-MM-dd'), format(this.state.chbkToTime,'HH:mm:ss'),
				this.state.acquirerAccountId,  this.state.accountPoolId)
            .then((res) => { 
				this.setState({resultByCurrency: res.data,loading: false}) 
            }).catch((e) => {
				 this.setState({loading: false,failed: true, success: false, responsemessage: e})
			});
		} 
	}
	
	
	ITEM_HEIGHT = 50;
	ITEM_PADDING_TOP = 8;
 	MenuProps = {
	  PaperProps: {
	    style: {
	      maxHeight: this.ITEM_HEIGHT * 4.5 + this.ITEM_PADDING_TOP,
	      width: 250,
	    },
	  },
	};
	TerminalMenuProps = {
	  PaperProps: {
	    style: {
	      maxHeight: this.ITEM_HEIGHT * 5.5 + this.ITEM_PADDING_TOP,
		  overflowX: 'auto',
	    },
	  },
	};
 	 
	render(){
		const { classes } = this.props;
		return (
			<React.Fragment>
				
			  <Paper style={{margin: '1em', padding: '1em'}}>
			   <Typography component="h2" variant="h6" color="primary" gutterBottom>
			      Merchant Transaction Summary
			   </Typography>
				{this.state.failed &&  <Alert severity="error" elevation="0"> {this.state.responsemessage}</Alert> }
				{this.state.success &&  <Alert severity="success" elevation="0"> {this.state.responsemessage}</Alert> }
				<Divider light className={classes.searchbar}/>
				  <Grid container xs={12} spacing={1}>				  
				  <MuiPickersUtilsProvider utils={DateFnsUtils} >
							  <DatePicker autoOk 
								label="From Date"
								format="MM/dd/yyyy"
								value={this.state.fromDate} 
								onChange={(val)=> this.setState({fromDate: val})} />
								&nbsp;	&nbsp;	&nbsp;
							 <TimePicker 
								label="From Time"
								 views={['hours', 'minutes', 'seconds']}
								value={this.state.fromTime} 
								format="HH:mm:ss"
								onChange={(val)=> this.setState({fromTime: val})}> 
							</TimePicker>
							&nbsp;	&nbsp;	&nbsp;
							 <DatePicker autoOk
						          label="To Date"
						          format="MM/dd/yyyy"
						          value={this.state.toDate}
						          onChange={(val) =>  this.setState({toDate: val})}
						        />
							&nbsp;	&nbsp;	&nbsp;
							<TimePicker 
								label="To Time"
								 views={['hours', 'minutes', 'seconds']}
								value={this.state.toTime} 
								format="HH:mm:ss"
								onChange={(val)=> this.setState({toTime: val})}> 
							</TimePicker>
				  </MuiPickersUtilsProvider>
				  <Typography style={{paddingTop: '1em',paddingBottom: '1em'}}  variant="subtitle2">(Dates max 1 month apart)</Typography>
				  </Grid>
				   <Grid container xs={12} spacing={1}>
				  <FormControl className={classes.formControlList} style={{ marginTop: '1em'}}>
					  <InputLabel id="merchantTerminalSelectLabel">Terminal</InputLabel>
					  <Select value={this.state.terminalId}  MenuProps={this.TerminalMenuProps}
					  		onChange={this.handleTerminalIdChange} name="terminalId"
					  		labelId="merchantTerminalSelectLabel" id="merchantTerminalSelect"
	 					>
						<MenuItem key="" value="">&nbsp; </MenuItem>	
						 {	this.state.merchantTerminals && this.state.merchantTerminals.map((mt) =>
						  	 <MenuItem key={mt.terminalId} value={mt.terminalId} dense>{mt.label + "-" + mt.terminalCardType}</MenuItem>
						)}
					  </Select>
				  </FormControl>	
				  <Typography style={{paddingTop: '2em',paddingLeft: '1.5em', marginRight: '1.5em'}} variant="subtitle1"> OR </Typography>		
				  <FormControl className={classes.formControlList} style={{ marginTop: '1em'	}}>
						 <InputLabel id="poolSelectLabel">Account Pool</InputLabel>
						 <Select value={this.state.accountPoolId}  MenuProps={this.MenuProps}
					  		onChange={this.handleAccountPoolChange} name="accountPoolId"
					  		labelId="poolSelectLabel" id="accountPoolIdSelect">

							<MenuItem key="" value="">All </MenuItem>
							
							{	this.state.accountPoolIdList && this.state.accountPoolIdList.map((pool) =>
						  	 <MenuItem key={pool} value={pool} dense>{pool}</MenuItem>
						)} 
						 </Select>
				  </FormControl> 
				  <FormControl  style={{marginLeft: '1.5em', marginTop: '1em', width: '40em'}}>
					  <InputLabel htmlFor="acquirerAccountSelect" shrink >AcquirerAccount</InputLabel>
					  <Select native value={this.state.acquirerAccountId}  MenuProps={this.TerminalMenuProps}
					  		onChange={this.handleAcquirerAccountChange} name="acquirerAccountId" multiple
					  		labelId="acquirerAccountSelect" id="merchantTerminalSelect"
	 					>
						<MenuItem key="" value="">&nbsp; </MenuItem>	
						 {	this.state.acquirerAccounts && this.state.acquirerAccounts.map((mt) =>
						  	 <option style={{fontSize:'12px', paddingTop: '3px'}} key={mt.acquirerAccountId} value={mt.acquirerAccountId}> {mt.mid + "-" + mt.accountNumber + "-" + mt.userName + "-" + mt.cardType.type} </option>
						)}
					  </Select>
				  </FormControl>	
				</Grid>
				 
				<Grid container xs={12} spacing={1} style={{paddingTop: '1em',paddingBottom: '1em'}}>	
					 <MuiPickersUtilsProvider utils={DateFnsUtils} >
							  <DatePicker autoOk 
								label="Refund from"
								format="MM/dd/yyyy"
								value={this.state.refundFromDate} 
								onChange={(val)=> this.setState({refundFromDate: val})} />
								&nbsp;	&nbsp;	&nbsp;
							 <TimePicker 
								label="Refund From Time"
								 views={['hours', 'minutes', 'seconds']}
								value={this.state.refundFromTime} 
								format="HH:mm:ss"
								onChange={(val)=> this.setState({refundFromTime: val})}> 
							</TimePicker>
							&nbsp;	&nbsp;	&nbsp;
							 <DatePicker autoOk
						          label="Refund To"
						          format="MM/dd/yyyy"
						          value={this.state.refundToDate}
						          onChange={(val) =>  this.setState({refundToDate: val})}
						        />
							&nbsp;	&nbsp;	&nbsp;
							<TimePicker 
								label="Refund To Time"
								 views={['hours', 'minutes', 'seconds']}
								value={this.state.refundToTime} 
								format="HH:mm:ss"
								onChange={(val)=> this.setState({refundToTime: val})}> 
							</TimePicker>
				  </MuiPickersUtilsProvider>
				</Grid>
				<br/>
				<Grid container xs={12} spacing={1}>	
					 <MuiPickersUtilsProvider utils={DateFnsUtils} >
							  <DatePicker autoOk 
								label="Chargeback from"
								format="MM/dd/yyyy"
								value={this.state.chbkFromDate} 
								onChange={(val)=> this.setState({chbkFromDate: val})} />
								&nbsp;	&nbsp;	&nbsp;
							 <TimePicker 
								label="Chargeback From Time"
								 views={['hours', 'minutes', 'seconds']}
								value={this.state.chbkFromTime} 
								// format="HH:mm:ss"
								onChange={(val)=> this.setState({chbkFromTime: val})}> 
							</TimePicker>
							&nbsp;	&nbsp;	&nbsp;
							 <DatePicker autoOk
						          label="Chargeback To"
						          format="MM/dd/yyyy"
						          value={this.state.chbkToDate}
						          onChange={(val) =>  this.setState({chbkToDate: val})}
						        />
							&nbsp;	&nbsp;	&nbsp;
							<TimePicker 
								label="Chargeback To Time"
								 views={['hours', 'minutes', 'seconds']}
								value={this.state.chbkToTime} 
								format="HH:mm:ss"
								onChange={(val)=> this.setState({chbkToTime: val})}> 
							</TimePicker>
				  </MuiPickersUtilsProvider>
				</Grid>
				
			  	<Grid justify="center" alignItems="center" style={{marginTop: '2em'}} container xs={12}>
			  	  <Grid item xs={2}>
			  	    <Button
				        variant="contained"
				        color="primary"
				        size="small"
				        className={classes.button}
				        startIcon={<SearchIcon />}
						onClick={this.searchTransactions} 
						disabled={this.state.loading}
				      >
				        Search
				    </Button>
				    {this.state.loading && <CircularProgress size={25} className={classes.buttonProgress} />}
				    </Grid>
				 
 					<Grid item xs={2}>
			  	    <Button
				        variant="contained"
				        color="primary"
				        size="small"
				        className={classes.button}
				        startIcon={<PriceCheckIcon />}
						onClick={this.reconTransactions} 
						disabled={this.state.reconLoading}
				      >
				        Reconcile
				    </Button>
				    {this.state.reconLoading && <CircularProgress size={25} className={classes.buttonProgress} />}
				    </Grid>
					 
			  	</Grid>
		
		      	{this.state.result &&
				<TableContainer component={Paper}>
					 <Table aria-label="simple table" className={classes.table}>
						<TableHead className={classes.head}>
				          <TableRow>
				            <TableCell>Category</TableCell>
				            <TableCell align="right">Count</TableCell>
				            <TableCell align="right">Amount</TableCell>
							<TableCell align="right">Settled Count</TableCell>
				            <TableCell align="right">Settled Amount</TableCell>
							<TableCell align="left">Action</TableCell>
				          </TableRow>
				        </TableHead>
						<TableBody>
							 <TableRow>
								<TableCell align="left">Successful Transactions</TableCell>
								<TableCell align="right">{this.state.result.successfulTxnCount}</TableCell>
								<TableCell align="right">{this.state.result.successfulTxnAmount}</TableCell>
								<TableCell align="right">{this.state.result.successfulSettledCount}</TableCell>
								<TableCell align="right">{this.state.result.successfulSettledAmount}</TableCell>
								<TableCell align="left">
									{this.state.result.successfulTxnCount != this.state.result.successfulSettledCount && this.state.terminalId == null && this.state.canModify  && 
										<Button color="primary" variant="contained" onClick={this.markSuccessSettled} size="small" disabled={this.state.loading}> Mark Settled </Button>
										
									}
								
								</TableCell>				            	
							</TableRow>
							<TableRow>
								<TableCell align="left">Declined Transactions</TableCell>
								<TableCell align="right">{this.state.result.declinedTxnCount}</TableCell>
								<TableCell align="right">{this.state.result.declinedTxnAmount}</TableCell>
								<TableCell align="right">{this.state.result.declinedSettledCount}</TableCell>
								<TableCell align="right">{this.state.result.declinedSettledAmount}</TableCell>		
								<TableCell align="left">
									{this.state.result.declinedTxnCount != this.state.result.declinedSettledCount && 
										<Button color="primary" variant="contained" onClick={this.markDeclinedSettled} size="small" disabled={this.state.loading}> Mark Settled </Button>
										
									}
								
								</TableCell>		            	
							</TableRow>
							<TableRow>
								<TableCell align="left">Refund Transactions</TableCell>
								<TableCell align="right">{this.state.result.refundTxnCount}</TableCell>
								<TableCell align="right">{this.state.result.refundTxnAmount}</TableCell>
								<TableCell align="right">{this.state.result.refundSettledCount}</TableCell>
								<TableCell align="right">{this.state.result.refundSettledAmount}</TableCell>
								<TableCell align="left"></TableCell>
							</TableRow>
							<TableRow>
								<TableCell align="left">Chargeback Transactions</TableCell>
								<TableCell align="right">{this.state.result.chbkTxnCount}</TableCell>
								<TableCell align="right">{this.state.result.chbkTxnAmount}</TableCell>
								<TableCell align="right">{this.state.result.chbkSettledCount}</TableCell>
								<TableCell align="right">{this.state.result.chbkSettledAmount}</TableCell>
								<TableCell align="left"></TableCell>
							</TableRow>
						</TableBody>		
					 </Table>
				</TableContainer>	}
				{this.state.resultByCurrency && this.state.resultByCurrency.map((r) => 
					<>
						<TableContainer component={Paper}>
						 <Table aria-label="simple table" className={classes.table}>
							<TableHead className={classes.head}>
					          <TableRow>
					            <TableCell>Category ({r.currency})</TableCell>
					            <TableCell align="right">Count</TableCell>
					            <TableCell align="right">Amount</TableCell>
								<TableCell align="right">Settled Count</TableCell>
				           		<TableCell align="right">Settled Amount</TableCell>
								<TableCell align="left">Action</TableCell>
					          </TableRow>
					        </TableHead>
							<TableBody>
								 <TableRow>
									<TableCell align="left">Successful Transactions</TableCell>
									<TableCell align="right">{r.successfulTxnCount}</TableCell>
									<TableCell align="right">{r.successfulTxnAmount}</TableCell>
									<TableCell align="right">{r.successfulSettledCount}</TableCell>
									<TableCell align="right">{r.successfulSettledAmount}</TableCell>
									<TableCell align="left">
										{r.successfulTxnCount != r.successfulSettledCount && this.state.terminalId == null && this.state.canModify  && 
										<>
										<Button color="primary" variant="contained" onClick={this.markSuccessSettled} size="small" disabled={this.state.loading}> Mark Settled </Button>
										<Button color="primary" variant="contained" onClick={this.markRRSettled} size="small" disabled={this.state.loading}>Mark RR Settled  </Button>
										</>
										}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell align="left">Declined Transactions</TableCell>
									<TableCell align="right">{r.declinedTxnCount}</TableCell>
									<TableCell align="right">{r.declinedTxnAmount}</TableCell>
									<TableCell align="right">{r.declinedSettledCount}</TableCell>
									<TableCell align="right">{r.declinedSettledAmount}</TableCell>
									<TableCell align="left">
									{r.declinedTxnCount != r.declinedSettledCount && 
										<Button color="primary" variant="contained" onClick={this.markDeclinedSettled} size="small" disabled={this.state.loading}> Mark Settled </Button>
										
									}
								
								</TableCell>
								</TableRow>
								<TableRow>
									<TableCell align="left">Refund Transactions</TableCell>
									<TableCell align="right">{r.refundTxnCount}</TableCell>
									<TableCell align="right">{r.refundTxnAmount}</TableCell>
									<TableCell align="right">{r.refundSettledCount}</TableCell>
									<TableCell align="right">{r.refundSettledAmount}</TableCell>
									<TableCell align="left"></TableCell>
								</TableRow>
								<TableRow>
									<TableCell align="left">Chargeback Transactions</TableCell>
									<TableCell align="right">{r.chbkTxnCount}</TableCell>
									<TableCell align="right">{r.chbkTxnAmount}</TableCell>
									<TableCell align="right">{r.chbkSettledCount}</TableCell>
									<TableCell align="right">{r.chbkSettledAmount}</TableCell>
									<TableCell align="left"></TableCell>
								</TableRow>
							</TableBody>		
						 </Table>
						</TableContainer>
					</>	
				)}
			</Paper>
			</React.Fragment>
		);
	}
}	

export default withStyles(useStyles)(MerchantTransactionSummaryComponent);

