import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import ApiService from './ApiService';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import { forwardRef } from 'react';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import AddBox from '@material-ui/icons/AddBox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {currency} from './currency';
import {country} from './country';

const useStyles = theme => ({	

	detailhead: {
	   fontSize: 12,
	},
	formControl: { 
	  marginLeft: 20,
	  minWidth: 120,
	  maxWidth: 300,
	},
	buttonProgress: {
	  position: 'absolute',
	  marginTop: 8,
	  marginLeft: -65,
	},
	button: {
	  margin: theme.spacing(1),
	},    
	checkbox:{
		width:40,						
	}
	
});

const validiPRegex = RegExp(
	/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/i 	   
);

const validEmailRegex = RegExp(
	/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);

const validateForm = errors => {
	let valid = true;
	Object.values(errors).forEach(val => val.length > 0 && (valid = false));
	return valid;
};

class EditFraudRulesForm extends Component {

	constructor(props) {
        super(props)
        this.state = {
            merchantId: this.props.merchantId,
			selectedMerchant: null,
			selectedTerminal: null,
			failed: false,
			success: false,
			responsemessage: null,
			merchants: [],
			rules: [],		
			fraudrulesmer:[],
			loading: false,		
			fraudName:'',			
			previous:[],	
			isChecked:false,
			stateofRules:{
				Block_Country_By_IPaddress:false,
				Block_Country_By_BIN:false,
				BIN_IP_Country_Mismatch:false,
				Block_IPaddress:false,
				Block_BIN:false,
				Allowed_Email_Per_Card:false,
				Allowed_Card_Per_Email:false,
				Block_Card_By_Usage:false,
				Block_Card_By_Type:false,

				Ip_Country:false,
				Billing_Country:false,			
				Currency:false,
				Amount:false,
				Class:false

			},		
			rulesvalue:{
				Block_Country_By_IPaddress:'',
				Block_Country_By_BIN:'',
				BIN_IP_Country_Mismatch:'',
				Block_IPaddress:'',
				Block_BIN:'',
				Allowed_Email_Per_Card:'',
				Allowed_Card_Per_Email:'',
				Block_Card_By_Usage:'',
				Block_Card_By_Type:'',

				Ip_Country:'',
				Billing_Country:'',				
				Currency:'',
				Amount:'',
				Class:''
			},						
			errors: {
				Block_Country_By_IPaddress:'',
				Block_Country_By_BIN:'',
				BIN_IP_Country_Mismatch:'',
				Block_IPaddress:'',
				Block_BIN:'',
				Allowed_Email_Per_Card:'',
				Allowed_Card_Per_Email:'',
				Block_Card_By_Usage:'',
				Block_Card_By_Type:'',
				
				Ip_Country:'',
				Billing_Country:'',				
				Currency:'',
				Amount:'',
				Class:''
			}
		}
		this.handleChange = this.handleChange.bind(this);
		this.reloadData = this.reloadData.bind(this);		
		this.showFraudRules = this.showFraudRules.bind(this);		
		this.fetchAllMerchants = this.fetchAllMerchants.bind(this);		
		this.handleSubmit = this.handleSubmit.bind(this);
		this.showRulesOfSelectedMerchant = this.showRulesOfSelectedMerchant.bind(this);	
		this.handledropdown = this.handledropdown.bind(this);
		this.handlerulesChange = this.handlerulesChange.bind(this);
		this.validationforRules = this.validationforRules.bind(this);
		this.reloadData();
				
	}	
	
	handleSubmit(){										

		var counter = 0;			
		
		//check if the checbox is ticked or not
		Object.keys(this.state.stateofRules).map(key  => {
			if(this.state.stateofRules[key] == true){

				//check for each value
				Object.keys(this.state.rulesvalue).map(key2  => {										
					if(key == key2){
												
						if(this.state.rulesvalue[key2].length==0){
							
							this.validationforRules(key2,this.state.rulesvalue[key2]);
							this.setState({success: false, failed: true, responseMessage: 'Invalid Form'});	

						}else{
							Object.keys(this.state.stateofRules).map(key  => {
								if(this.state.stateofRules[key] == true){
									this.validationforRules(key,this.state.rulesvalue[key]);
								}
							})			
							{validateForm(this.state.errors) ? 
								ApiService.addFraduRule(key,this.state.merchantId,this.state.rulesvalue[key2]).then((res)=>{
									if(res.data.status === 'OK') {				
										this.setState({success: true, failed: false, responseMessage:res.data.responseMessage});				
									} else {
										this.setState({success: false, failed: true, responseMessage: res.data.responseMessage});
									}
								})
								:
								this.setState({success: false, failed: true, responseMessage: 'Invalid Form'});	
							}
						}														
					}

				})					
				
			}else{
				counter++;							
			}				
		})
			
		if(counter == Object.keys(this.state.stateofRules).length){
			this.setState({success: false, failed: true, responseMessage: "Select Rules to apply"});
		}										
		
	}

	componentDidMount(){
		this.showFraudRules();	
	}

	handledropdown(event){

		// to find out if it's checked or not; returns true or false
		const checked = event.target.checked;

		// to get the checked value
		const checkedValue = event.target.value;

		// to get the checked name
		const checkedName = event.target.name;

		Object.keys(this.state.stateofRules).map(key  => {
			
			let errors = this.state.errors;	
			if(checkedName == key){				
				this.state.stateofRules[key] = checked;	
				if(checked==false){					
					
					Object.keys(this.state.errors).map(key2  => {
						if(key2 == key){
							console.log(errors[key2]);	
							errors[key2]='';
						}
					})				
					
					this.setState({errors});
				}
							
			}

		})				
				
	}

	validationforRules(name,value){

		let errors = this.state.errors;		
		console.log(name);
		console.log(value);
		switch (name) {
			case 'Block_Country_By_IPaddress': 
				errors.Block_Country_By_IPaddress = 
				value ==''
					? 'Select Country'
					: '';			
				break;
			case 'Block_Country_By_BIN': 
				errors.Block_Country_By_BIN = 
				value ==''
					? 'Select Country'
					: '';				
				break;
			case 'BIN_IP_Country_Mismatch': 
				errors.BIN_IP_Country_Mismatch = 
				value.length != 2
					? 'Country should only have 2 chars'
					: '';
				break;
			case 'Block_IPaddress': 
				errors.Block_IPaddress = 
				validiPRegex.test(value)
					? ''
					: 'IP address not valid';
				break;
			case 'Block_BIN': 
				errors.Block_BIN = 
				value.length==0 || isNaN(value) || value.length > 6
					? 'BIN number not valid'
					: '';
				break;
			case 'Allowed_Email_Per_Card': 
				errors.Allowed_Email_Per_Card = 
				value==''
					? 'Select Number of emails'
					: '';
				break;
			case 'Allowed_Card_Per_Email': 
				errors.Allowed_Card_Per_Email = 
				value==''
					? 'Slect number of Cards'
					: '';
				break;
			case 'Block_Card_By_Type': 
				errors.Block_Card_By_Type = 
				value == ''
					? 'Select Card type'
					: '';
				break;
			case 'Block_Card_By_Usage': 
				errors.Block_Card_By_Usage = 
				isNaN(value) || value.length != 2
					? 'Number not valid'
					: '';					
				break;	
				
			case 'Ip_Country': 
				errors.Ip_Country = 
				validiPRegex.test(value)
					? ''
					: 'Ip not valid';					
				break;	
			case 'Billing_Country': 
				errors.Billing_Country = 
				value == ''
					? 'Select country'
					: '';	
				break;	
			case 'Currency':				
				errors.Currency = 
				value == ''
					? 'Select Currency'
					: '';	
					break;	
			case 'Amount': 
				errors.Amount = 
				value.length==0 || isNaN(value)
					? 'Amount not valid'
					: '';					
				break;	
			case 'Class': 
				errors.Class = 
				value.length==0 || value.length > 8
					? 'Class not valid'
					: '';					
				break;	
			default:
				break;
		}
		this.state.rulesvalue[name] = value;
		this.setState({errors});
	}

	handlerulesChange(event){
		
		let rulesvalue = this.state.rulesvalue;					
		let errors = this.state.errors;					
		event.preventDefault();
		const { name, value } = event.target;		

		if(value.length==0){
			console.log(errors.name);
			Object.keys(this.state.stateofRules).map(key  => {
				if(name == key){
					errors[key]='';	
					this.state.rulesvalue[key] = '';
				}			
			})
			this.setState({rulesvalue});	
			this.setState({errors});	
			
		}else{
			this.validationforRules(name,value);
		}
		
	}
		
	handleChange(event) {		

		const { name, value } = event.target;		
		this.setState({[name]: value,success:false});
    }

	reloadData() {
		this.fetchAllMerchants();
		this.setState({errors: ''});									
	} 	

	showRulesOfSelectedMerchant(){		

		ApiService.fetchFraudRulesByMerchantId(this.state.selectedMerchant.id)
			.then((res)=> {
				this.setState({fraudrulesmer: res.data})									
			});	
		
	}

	fetchAllMerchants(){
		ApiService.fetchAllMerchants()
			.then((res) => {
				this.setState({merchants: res.data})
			});		
	}

	showFraudRules() {
		ApiService.fetchFraudRules()
			.then((res)=> {
				this.setState({rules: res.data,terminalPanelVisible: false})							
			});					
	}			
	
	tableIcons = {
	    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
	    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
	    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
	    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
	    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
	    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
	    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
	    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
	    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
	    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
	    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
	    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
	    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
	};
    
	render() {
		const { classes } = this.props; 
		const {errors} = this.state;
	    return (
			<>																															
				{this.state.failed &&  <Alert severity="error" elevation="0"> {this.state.responseMessage}</Alert> }
				{this.state.success && <Alert severity="success" elevation="0">{this.state.responseMessage}</Alert>}
				&nbsp;
						
						{this.state.rules.map((m)=>
						<Grid container spacing={2}>
						<List>					
						<ListItem>
							<Grid>
							<input
							className={classes.checkbox}
							type="checkbox"
							name={m.fraudName}
							value={m.fraudName}
							onChange={this.handledropdown}
							/>
							<label for="element" style={{ fontSize: 15 , paddingRight:15 }}>
							{m.fraudDescription}
							</label>
							</Grid>
							<Grid> 
							<ListItemText>

								{m.fraudName =='Block_Country_By_IPaddress' &&
									<>
									<Select																		
									name="Block_Country_By_IPaddress"
									value={this.state.Block_Country_By_IPaddress}
									label="Block_Country_By_IPaddress"
									onChange={this.handlerulesChange}
									>	

									<MenuItem key="" value="">&nbsp; </MenuItem>										
									{country.map((m)=>
										<MenuItem value={m.name}>{m.name}({m.code})</MenuItem>
									)}										
									</Select>	
									<br/>
									{errors.Block_Country_By_IPaddress.length > 0 && 
										<span className='text-danger'>{errors.Block_Country_By_IPaddress}</span>}	
									</>
								}

								{m.fraudName =='Block_Country_By_BIN' &&
									<>
									<Select																		
									name="Block_Country_By_BIN"
									value={this.state.Block_Country_By_BIN}
									label="Block_Country_By_BIN"
									onChange={this.handlerulesChange}
									>	

									<MenuItem key="" value="">&nbsp; </MenuItem>										
									{country.map((m)=>
										<MenuItem value={m.name}>{m.name}({m.code})</MenuItem>
									)}										
									</Select>	
									<br/>								
									{errors.Block_Country_By_BIN.length > 0 && 
										<span className="text-danger">{errors.Block_Country_By_BIN}</span>}	
									</>
								}

								{m.fraudName =='BIN_IP_Country_Mismatch' &&
									<>
									<TextField name='BIN_IP_Country_Mismatch' onChange={this.handlerulesChange} placeholder="Rule Value"/>											
									<br/>
									{errors.BIN_IP_Country_Mismatch.length > 0 && 
										<span className='text-danger'>{errors.BIN_IP_Country_Mismatch}</span>}	
									</>
								}

								{m.fraudName =='Block_IPaddress' &&
									<>
									<TextField name='Block_IPaddress' onChange={this.handlerulesChange} placeholder="Rule Value"/>											
									<br/>
									{errors.Block_IPaddress.length > 0 && 
										<span className='text-danger'>{errors.Block_IPaddress}</span>}	
									</>
								}

								{m.fraudName =='Block_BIN' &&
									<>
									<TextField name='Block_BIN' onChange={this.handlerulesChange} placeholder="Rule Value"/>											
									<br/>
									{errors.Block_BIN.length > 0 && 
										<span className='text-danger'>{errors.Block_BIN}</span>}	
									</>
								}

								{m.fraudName=='Block_Card_By_Type' &&
									<>
									<Select																		
										name="Block_Card_By_Type"
										value={this.state.Block_Card_By_Type}
										label="Card Type"
										onChange={this.handlerulesChange}
									>
										<MenuItem key="" value="">&nbsp; </MenuItem>
										<MenuItem value={'Visa'}>Visa</MenuItem>
										<MenuItem value={'American Express'}>American Express</MenuItem>
										<MenuItem value={'Master Card'}>Master Card</MenuItem>
										<MenuItem value={'Discover'}>Discover</MenuItem>
									</Select>								
									<br/>
									{errors.Block_Card_By_Type.length > 0 && 
										<span className='text-danger'>{errors.Block_Card_By_Type}</span>}
									</>
								}																

								{m.fraudName =='Allowed_Email_Per_Card' &&
									<>
									<TextField
										id="outlined-number"
										name='Allowed_Email_Per_Card'
										onChange={this.handlerulesChange}
										label="Number"
										type="number"
										inputProps={{ min: 1, max: 5 }}
										InputLabelProps={{
											shrink: true,
										}}
									/>				
									<br/>
									{errors.Allowed_Email_Per_Card.length > 0 && 
										<span className='text-danger'>{errors.Allowed_Email_Per_Card}</span>}																
									</>
								}

								{m.fraudName =='Allowed_Card_Per_Email' &&
									<>
									<TextField
										id="outlined-number"
										name='Allowed_Card_Per_Email'
										onChange={this.handlerulesChange}
										label="Number"
										type="number"
										inputProps={{ min: 0, max: 10 }}
										InputLabelProps={{
											shrink: true,
										}}
									/>	
									<br/>
									{errors.Allowed_Card_Per_Email.length > 0 && 
										<span className='text-danger'>{errors.Allowed_Card_Per_Email}</span>}															
									</>
								}
						
								{m.fraudName =='Block_Card_By_Usage' &&
									<>
									<TextField name='Block_Card_By_Usage' onChange={this.handlerulesChange} placeholder="Rule Value"/>		
									<br/>									
									{errors.Block_Card_By_Usage.length > 0 && 
										<span className='text-danger'>{errors.Block_Card_By_Usage}</span>}	
									</>
								}
								
								{m.fraudName =='Ip_Country' &&
									<>
									<TextField name='Ip_Country' onChange={this.handlerulesChange} placeholder="Rule Value"/>											
									<br/>
									{errors.Ip_Country.length > 0 && 
										<span className='text-danger'>{errors.Ip_Country}</span>}	
									</>
								}
								
								{m.fraudName =='Billing_Country' &&
									<>																		
									<Select																		
									name="Billing_Country"
									value={this.state.Billing_Country}
									label="Billing_Country"
									onChange={this.handlerulesChange}
									>	

									<MenuItem key="" value="">&nbsp; </MenuItem>										
									{country.map((m)=>
										<MenuItem value={m.name}>{m.name}({m.code})</MenuItem>
									)}										
									</Select>	
									<br/>
									{errors.Billing_Country.length > 0 && 
										<span className='text-danger'>{errors.Billing_Country}</span>}	

								</>
								}

								{m.fraudName =='Currency' &&
									<>
									<Select																		
										name="Currency"
										value={this.state.Currency}
										label="Currency"
										onChange={this.handlerulesChange}
									>	

									<MenuItem key="" value="">&nbsp; </MenuItem>										
									{currency.map((m)=>
										<MenuItem value={m.cc}>{m.name}({m.symbol})</MenuItem>
									)}										
									</Select>	
									<br/>
									{errors.Currency.length > 0 && 
										<span className='text-danger'>{errors.Currency}</span>}	
									</>
								}

								{m.fraudName =='Amount' &&
									<>																							
									<TextField name='Amount' onChange={this.handlerulesChange} placeholder="Rule Value"/>																					
									<br/>
									{errors.Amount.length > 0 && 
										<span className='text-danger'>{errors.Amount}</span>}	
									</>
								}

								{m.fraudName =='Class' &&
									<>
									<TextField name='Class' onChange={this.handlerulesChange} placeholder="Rule Value"/>											
									<br/>
									{errors.Class.length > 0 && 
										<span className='text-danger'>{errors.Class}</span>}	
									</>
								}

								</ListItemText>
								</Grid>
								</ListItem>				
								</List>
								</Grid>
								
						)}
				

					
						<Grid>				
							<Button className={classes.button} 
							variant="contained" onClick={this.handleSubmit}  color="primary">Update</Button>
						</Grid>	
					
			
			</>
		);
	}
}
export default withStyles(useStyles)(EditFraudRulesForm);