import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {Divider, Typography, Paper, Grid,InputLabel, Select, MenuItem, Button, Box, FormControl,Chip } from '@material-ui/core';
import ApiService from './ApiService';
import MaterialTable from 'material-table';
import DateFnsUtils from '@date-io/date-fns';
import {subMonths,subDays, format} from 'date-fns';
import TableIcons from './TableLogoComponent';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import Alert from '@material-ui/lab/Alert';
import Backdrop from '@material-ui/core/Backdrop';
import SearchIcon from '@material-ui/icons/Search';
import CircularProgress from '@material-ui/core/CircularProgress';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import Check from '@material-ui/icons/Check';
import Avatar from '@material-ui/core/Avatar';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

import {
  MuiPickersUtilsProvider,
  DatePicker,
  DateTimePicker ,
  TimePicker 
} from '@material-ui/pickers';

const useStyles = theme => ({
 
  searchbar: {
	margin: theme.spacing(2),
  },
  formControl: {
    minWidth: 200,
  },

});

class RollingReserveComponent extends Component {

	constructor(props) {
		 super(props);
	 	 this.state = {		 
			failed: false,
			success: false, 
			responsemessage: null,
			fromDate: null,			
			toDate: new Date(),
			fromTime: (new Date()).setHours(0,0,0),	
			toTime: (new Date()).setHours(23,59,59),
			fromTimeStr: "00:00:00",	
			toTimeStr: "23:59:59",
			enitityId: null,
			merchantTerminals: [],
			wires: [],
			
		}
		this.handleChange = this.handleChange.bind(this);
		this.search = this.search.bind(this);
	 	this.fetchMerchantTerminals = this.fetchMerchantTerminals.bind(this);
		this.terminalSelected = this.terminalSelected.bind(this);
		this.updateRRStatus = this.updateRRStatus.bind(this);	
	}
	
	componentDidMount() { 
		this.fetchMerchantTerminals();
	 
	}
	fetchMerchantTerminals(){
		ApiService.fetchMerchantTerminals()
			.then((res)=> {
				this.setState({merchantTerminals: res.data})			
		}) 
	}
	handleChange(event) {
        this.setState(
            {
                [event.target.name]
                    : event.target.value, critChanged: true,
            }
        )
    }
	terminalSelected(event){
		this.handleChange(event);
		ApiService.fetchEarliestRRSettleDate(event.target.value).then((res) => {
			this.setState({fromDate: res.data})
		})
	}
	
	search() {
		
		var reqForm = {
			fromDate: format(this.state.fromDate,'yyyy-MM-dd'),
			toDate: format(this.state.toDate,'yyyy-MM-dd'),
			enitityId: this.state.enitityId,
		};
		var request = Object.assign({}, reqForm);
		ApiService.fetchWiresForRR(request)
			.then((res)=> {
				this.setState({wires: res.data})
			})
	}
	updateRRStatus(wireId){
		ApiService.updateRRStatus(wireId).then((res) => {
			if(res.data.status != "OK") {
				this.setState({failed: true, success: false, responsemessage: res.data.responseMessage})
			} else {
				this.setState({failed: false, success: true, responsemessage: res.data.responseMessage})
				this.search();
			}
			
		})
	}
	showPdf(wireId) {
		ApiService.fetchWirePdf(wireId, format(this.state.fromDateObj,"yyyyMMdd")+"_"+format(this.state.toDateObj,"yyyyMMdd"));
	}
	
	showXLS(wireId) {
		ApiService.fetchWireXls(wireId, format(this.state.fromDateObj,"yyyyMMdd")+"_"+format(this.state.toDateObj,"yyyyMMdd"));
	}
	
	TerminalMenuProps = {
	  PaperProps: {
	    style: {
	      maxHeight: this.ITEM_HEIGHT * 5.5 + this.ITEM_PADDING_TOP,
	      width: 500,
	    },
	  },
	};
	 render() {	
		 const { classes } = this.props; 
        return (             
            <Paper style={{margin: '1em', padding: '1em'}}>
				<Typography component="h2" variant="h6" color="primary" gutterBottom>
			      Rolling reserve management
			   </Typography>
				{this.state.failed &&  <Alert severity="error" elevation={0}> {this.state.responsemessage}</Alert> }
				{this.state.success &&  <Alert severity="success" elevation={0}> {this.state.responsemessage}</Alert> }
				<Grid container xs={12} spacing={1}>				  
				  <Grid item xs={12}>
				  <FormControl className={classes.formControl}>
						  <InputLabel id="merchantTerminalSelectLabel">Terminal</InputLabel>
						  <Select value={this.state.enitityId}  MenuProps={this.TerminalMenuProps}
						  		onChange={this.terminalSelected} name="enitityId"
						  		labelId="merchantTerminalSelectLabel" id="merchantTerminalSelect"
		 					>
							<MenuItem key="" value="">&nbsp; </MenuItem>	
							 {	this.state.merchantTerminals && this.state.merchantTerminals.map((mt) =>
							  	 <MenuItem key={mt.terminalId} value={mt.terminalId} dense>{mt.label + "-" + mt.terminalCardType}</MenuItem>
							)}
						  </Select>
				  </FormControl> 
				  &nbsp;	&nbsp;	&nbsp;
				  <MuiPickersUtilsProvider utils={DateFnsUtils} >
							  <DatePicker autoOk 
								label="From"
								 format="dd MMM yyyy"
								value={this.state.fromDate} 
								onChange={(val)=> this.setState({fromDate:  val})} />
								
								<TimePicker 
								label="From Time"
								 views={['hours', 'minutes', 'seconds']}
								value={this.state.fromTime} 
								format="HH:mm:ss"
								onChange={(val)=> this.setState({fromTime: val, fromTimeStr: format(val,'HH:mm:ss')})}> 
							</TimePicker>
							&nbsp;	&nbsp;	&nbsp;
							 <DatePicker autoOk
						          label="To"
						          format="dd MMM yyyy"
						          value={this.state.toDate}
						          onChange={(val) =>  this.setState({toDate : val})}
						        />
							<TimePicker 
								label="To Time"
								 views={['hours', 'minutes', 'seconds']}
								value={this.state.toTime} 
								format="HH:mm:ss"
								onChange={(val)=> this.setState({toTime: val, toTimeStr: format(val,'HH:mm:ss')})}> 
							</TimePicker> 
				  </MuiPickersUtilsProvider>
				  </Grid>
				</Grid>
				<Grid justify="center" alignItems="center" style={{marginTop: '2em'}} container xs={12}>
			  	  <Grid item xs={2}>
			  	    <Button
				        variant="contained"
				        color="primary"
				        size="small"
				        className={classes.button}
				        startIcon={<SearchIcon />}
						onClick={this.search}
				      >
				        Search
				    </Button>
					</Grid>
					<Grid item xs={2}>
				  	    <Button
					        variant="contained"
					        color="primary"
					        size="small"
					        className={classes.button}					        
							onClick={this.showCreateBox}
					      >
					       Generate
					    </Button>
				    <Backdrop open={this.state.loading} className={classes.backdrop} onClick={this.handleClose}>
				    <CircularProgress color="inherit" /></Backdrop>
				    </Grid>
			  	</Grid>
		
		<MaterialTable
				  style={{margin: '2em 0 0 0'}}
			      title="Rolling Reserve Statements"
				  icons={TableIcons.icons}
			      columns={[
					{ field: 'validFrom',title: 'From',render: rowData => <Typography noWrap="true" variant="caption">{format(new Date(rowData.validFrom),'dd MMM yyyy') }</Typography> },
					{ field: 'validTo',title: 'To',render: rowData => <Typography noWrap="true" variant="caption">{format(new Date(rowData.validTo),'dd MMM yyyy') }</Typography>},
			        { field: 'successTxnAmount', title: 'Successful txn amt'},  					
					{ field: 'reserveAmount', title: 'RR Amount'},
					{ field: 'currency', title: 'Currency'}, 
					{ field: 'approved', title: 'Approved'},
					{ field: 'reserveSettled', title: 'Reserve Settled', render: rowData =>  
						<><Typography noWrap="true" variant="caption">{ rowData.reserveSettled}</Typography>
							{ rowData.reserveSettled != null && rowData.reserveSettled === 'Y' &&
								<Chip label="Settled" color="success" variant="outlined" />
							}
							{  (rowData.reserveSettled == null  || rowData.reserveSettled === 'N') &&
								<Chip label="Pending" color="secondary" variant="outlined" />
							} 
						</>
						
					 },					
			      ]}
			      data={this.state.wires}
				  actions={[					
					{
						tooltip: 'Settle RR',
						icon: (props)=> <Check {...props} />,
						onClick: (evt, rowData) => this.updateRRStatus(rowData.wireId),
					},
					{
						tooltip: 'Report PDF',
						icon: (props)=> <PictureAsPdfIcon {...props} />,
						onClick: (evt, rowData) => this.showPdf(rowData.wireId),
					},
					{
						tooltip: 'Transactions XLS',
						icon: (props)=> <Avatar variant='square' className={classes.small} src='xls-icon.png' {...props} />,
						onClick: (evt, rowData) => this.showXLS(rowData.wireId),
					}
					
						
				  ]}
				  options={{
					search:false,
			        exportButton: true,
					pageSize: 20,
					pageSizeOptions: [20,50,100],
					padding: 'default',
					showEmptyDataSourceMessage: true,
					selection: false,
					actionsColumnIndex: -1,
					tableLayout: 'auto',
					emptyRowsWhenPaging: false,
			      }} 
			    />
			</Paper>
        )
    }
}

export default withStyles(useStyles)(RollingReserveComponent)