import React, { Component }  from 'react';
import './App.css';
import InstructorApp from './InstructorApp';
import { createMuiTheme , ThemeProvider} from '@material-ui/core/styles';

const theme = createMuiTheme({
	  palette: {
	   primary: {
	      light: '#5A2A7E',
	      main: '#5A2A7E',
	      dark: '#1c313a',
	      contrastText: '#fff',
	    },
	    secondary: {
	      light: '#ff7961',
	      main: '#d4b1a7',
	      dark: '#ba000d',
	      contrastText: '#000',
	    },
	  },
	});
class App extends Component {
	 
  render() {

    return ( 
     <ThemeProvider theme={theme}>
        <InstructorApp />
     </ThemeProvider>
      
  );
  }
}

export default App;