import React, { useState, useEffect } from 'react';
import "./App.css";
import "./index.css";
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import DoughnutChart from './DoughnutChart';
import BarChart from './BarChart';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { red, green, blue, orange } from '@material-ui/core/colors';
import ReceiptIcon from '@material-ui/icons/Receipt';
import RestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import RefundIcon from '@material-ui/icons/RemoveShoppingCart';
import PaymentIcon from '@material-ui/icons/Payment';
import ApiService from './ApiService';
import { Avatar, Grid, Button, Typography, CardHeader, Card, CardContent, CardActions,CardActionArea, CardMedia, Box, Paper, CircularProgress,Backdrop } from '@material-ui/core/';

const useStyles = makeStyles(theme => ({
	
	avatarCheck: {
		backgroundColor: 'rgb(99, 117, 98)' ,

	},
	avataSett: {
		backgroundColor: 'rgb(173, 206, 171)',

	},
	avataChbk: {
		backgroundColor: 'rgb(153, 65, 65)',

	},
	avataRefund: {
		backgroundColor: 'rgba(239, 192, 4, 0.91)',

	},
	avataDecline: {
		backgroundColor: ' rgb(35, 75, 119)',

	},
	backdrop: {
    	zIndex: theme.zIndex.drawer + 1,
    	color: '#fff',
  	},
}));

export default function DashboardComponent() {
	const classes = useStyles();
	const [txnSummary, setTxnSummary] = useState();
	const [monthSummary, setMonthSummary] = useState();
	const [open, setOpen] = React.useState(false);
	
	const handleClose = () => {
	    setOpen(false);
	};
	const handleToggle = () => {
	    setOpen(!open);
	};
	useEffect(() => { 
			handleToggle();
			const txnFn = async () =>  { 
				const t1 = await ApiService.fetchTxnStatusSummary();				
				const t2 = await ApiService.fetchMonthsTxnSummary();
				setTxnSummary(t1.data);
				setMonthSummary(t2.data);
				handleClose();
			};
			txnFn(); 
			
	 }, []);
	
	 
	return (

		<Paper style={{ margin: '1em', padding: '1em' }}>
			<Grid container direction="row"  spacing={2} justify="flex-start">
				<Grid item >
					<Card>
						<CardHeader
							avatar={
								<Avatar aria-label="Successful" className={classes.avatarCheck}>
									<CheckCircleIcon />
								</Avatar>
							}
							title="Successful"
							subheader={
								<><Typography variant="caption"> ${txnSummary && txnSummary.successfulTxnAmount.toFixed(2)}</Typography><br/>
								<Typography  variant="caption"> Count: {txnSummary && txnSummary.successfulTxnCount}</Typography></>
							} 
						/>
					</Card >
				</Grid>
				
				<Grid item>
					<Card>
						<CardHeader
							avatar={
								<Avatar aria-label="Settled" className={classes.avataSett}>
									<ReceiptIcon />
								</Avatar>
							}
							title="Settled"
							subheader={
								<><Typography variant="caption"> ${txnSummary && txnSummary.successfulSettledAmount.toFixed(2)}</Typography><br/>
								<Typography  variant="caption"> Count: {txnSummary && txnSummary.successfulSettledCount}</Typography></>
							} 
						/>
					</Card >

				</Grid>
				<Grid item> 
					<Card>
						<CardHeader
							avatar={
								<Avatar aria-label="Decline" className={classes.avataDecline}>
									<PaymentIcon />
								</Avatar>
							}
							title="Decline"
							subheader={
								<><Typography variant="caption"> ${txnSummary && txnSummary.declinedTxnAmount.toFixed(2)}</Typography><br/>
								<Typography  variant="caption"> Count: {txnSummary && txnSummary.declinedTxnCount}</Typography></>
							} 
						/>
					</Card > 
				</Grid>
				<Grid item>
					<Card>
						<CardHeader
							avatar={
								<Avatar aria-label="Chargeback" className={classes.avataChbk}>
									<RefundIcon />
								</Avatar>
							}
							title="Chargeback"
							subheader={
								<><Typography variant="caption"> ${txnSummary && txnSummary.chbkTxnAmount.toFixed(2)}</Typography><br/>
								<Typography  variant="caption"> Count: {txnSummary && txnSummary.chbkTxnCount}</Typography></>
							} 
						/>
					</Card >
				</Grid>
				<Grid item > 
					<Card>
						<CardHeader
							avatar={
								<Avatar aria-label="Refund" className={classes.avataRefund}>
									<RestoreIcon />
								</Avatar>
							}
							title="Refund"
							subheader={
								<><Typography variant="caption"> ${txnSummary && txnSummary.refundTxnAmount.toFixed(2)}</Typography><br/>
								<Typography  variant="caption"> Count: {txnSummary && txnSummary.refundTxnCount}</Typography></>
							} 
						/>
					</Card > 
				</Grid>
				
			</Grid>
			<Grid container direction="row"  
					spacing={2} xs={12} style={{ paddingTop: '1em' }}>
				<Grid item >
					 <BarChart monthSummary={monthSummary} /> 
				</Grid>
				<Grid item >
					 <DoughnutChart txnSummary={txnSummary}/> 
				</Grid>
			</Grid>
		 <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
		  <CircularProgress color="inherit" />
		</Backdrop>
			
		</Paper>
		


	)
}