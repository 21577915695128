import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TableIcons from './TableLogoComponent';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ApiService from './ApiService';
import Divider from '@material-ui/core/Divider';
import MaterialTable from 'material-table';
import EditIcon from '@material-ui/icons/Edit';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import Alert from '@material-ui/lab/Alert';
import CheckIcon from '@material-ui/icons/CheckCircle';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import ConfirmDialog from './ConfirmDialog';


const useStyles = theme => ({
	root: {
		display: 'flex',
	},
});
class MerchantOnboardingComponent extends Component {
	constructor(props) {
		super(props)
		this.state = {
			merchants: [],	
			documents: [],	
			showDetails: false,
			selectedMerchant: null,
			failed: false,
			success: false,
			responsemessage: null,
			showConfirm: false,
		}
		this.handleChange = this.handleChange.bind(this);
		this.reloadData = this.reloadData.bind(this);
		this.approveMerchant = this.approveMerchant.bind(this);
		this.showDetailsPanel = this.showDetailsPanel.bind(this);
		this.hideDetailsPanel = this.hideDetailsPanel.bind(this);
		this.showConfirm = this.showConfirm.bind(this);
		this.setShowConfirm = this.setShowConfirm.bind(this);
	}
	
	handleChange(event) {
        this.setState(
            {
                [event.target.name]
                    : event.target.value
            }
        )
    }

	componentDidMount() {
		this.reloadData();
	}
	setShowConfirm(value) {
		this.setState({showConfirm: value});
	}
	reloadData(){
		ApiService.fetchOnboardingMerchants().then((res) => {
			this.setState({merchants: res.data});
		})
	}
	openFile(id, fileName) {
		/*const file = new Blob([doc.data],{type: 'image/*'});
		 
		var link = document.createElement('a');
		link.href = URL.createObjectURL(file);
		link.download = doc.fileName;
  		document.body.appendChild(link);
    	link.click();
    	document.body.removeChild(link);*/
		ApiService.downloadMerchantDoc(id, fileName);
	}
	showConfirm(data){
		this.setState({success: false, failed: false,showConfirm: true, selectedMerchant: data[0]});	
	}
	approveMerchant(){
	
		if(this.state.selectedMerchant != null) {
			ApiService.approveMerchant(this.state.selectedMerchant.id).then((res) => {
				if(res.data.status == 'OK') {
					this.setState({success: true, failed: false, responsemessage: res.data.responseMessage});
				} else {
					this.setState({success: false, failed: true, responsemessage: res.data.responseMessage});
				}
			})
		}
		this.reloadData();
		this.setState({selectedMerchant: null});
	}

	showDetailsPanel(data){		
		ApiService.fetchMerchantDocuments(data[0].id).then((res) => {
			this.setState({documents: res.data})
		})
		this.setState({selectedMerchant: data[0], showDetails: true})
	}
	fetchMerchantDocs(){
		
	}
	hideDetailsPanel(){
		this.setState({showDetails: false})
	}
	
	render() {
		const { classes } = this.props;
		
		return (
				<Paper style={{margin: '1em', padding: '1em'}}>
					   <Typography component="h2" variant="h6" color="primary" gutterBottom>
					      Merchant Onboarding
					   </Typography>
		
						<Divider light className={classes.searchbar}/>
						{this.state.failed &&  <Alert severity="error" elevation="0"> {this.state.responsemessage}</Alert> }
						{this.state.success && <Alert severity="success" elevation="0">{this.state.responsemessage}</Alert>}
						<MaterialTable					  
						      title="Newly Registered Merchants" 
							  icons={TableIcons.icons}
						      columns={[				       
								{ field: 'id', title: 'Id',render: rowData => <>
									<Tooltip title={rowData.id} interactive>
										 <InfoOutlinedIcon/>
									</Tooltip>
								</>
								},			
								{ field: 'merchantName' , numeric: false, title: 'Name'},
								{ field: 'contactEmail' ,numeric: false, title: 'Email'},	 
								{ field: 'telephone' ,numeric: false, title: 'Phone'},	 
						        { field: 'orgRegistrationNumber' ,numeric: false, title: 'Reg No'},
						      ]}
						      data={this.state.merchants}
							  actions={[
								 
								{
									tooltip: 'Approve merchant',
									icon: (props)=> <CheckIcon {...props} />,
									onClick: (evt, data) => this.showConfirm(data),
								},
								{
									tooltip: 'Show details',
									icon: (props)=> <Button variant="contained" color="primary" size="small" className={classes.button}>Details</Button>,
									onClick: (evt, data) => this.showDetailsPanel(data),
								}
							  ]}
							  options={{
								search:false,
						        exportButton: false,
								pageSize: 30,
								pageSizeOptions: [15,30,45],
								padding: 'dense',
								showEmptyDataSourceMessage: true,
								selection: true,
								emptyRowsWhenPaging: false,
						      }}				     
						    />


					<Dialog open={this.state.showDetails} onClose={this.hideDetailsPanel}>
						<DialogTitle id="customized-dialog-title" onClose={this.hideDetailsPanel}>
			          		Merchant details
			        	</DialogTitle>
						<DialogContent>
							<TableContainer component={Paper}>
							{this.state.selectedMerchant &&
								<Table>
									<TableBody>
										<TableRow>
											<TableCell align="left">Merchant name</TableCell>
             								<TableCell align="left">{this.state.selectedMerchant.merchantName}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell align="left">Contact Email</TableCell>
             								<TableCell align="left">{this.state.selectedMerchant.contactEmail}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell align="left">Address</TableCell>
             								<TableCell align="left">{this.state.selectedMerchant.address},{this.state.selectedMerchant.city},{this.state.selectedMerchant.state},{this.state.selectedMerchant.country}, {this.state.selectedMerchant.zip}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell align="left">Telephone</TableCell>
             								<TableCell align="left">{this.state.selectedMerchant.telephone}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell align="left">Fax</TableCell>
             								<TableCell align="left">{this.state.selectedMerchant.fax}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell align="left">Registration number</TableCell>
             								<TableCell align="left">{this.state.selectedMerchant.orgRegistrationNumber}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell align="left">Industry</TableCell>
             								<TableCell align="left">{this.state.selectedMerchant.industry}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell align="left">Website</TableCell>
             								<TableCell align="left">{this.state.selectedMerchant.website}</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							 }
							</TableContainer>
							<Typography style={{padding: '1em'}}>Merchant Documents </Typography>
							<TableContainer component={Paper}>
								
								<Table>
									<TableBody>
										{this.state.documents && this.state.documents.map((doc) => 
											 
											<TableRow>
												<TableCell align="left">{doc.fileName}</TableCell>
	             								<TableCell align="left">
													<DownloadIcon onClick={() => this.openFile(doc.id, doc.fileName)}/>
				 								</TableCell>
											</TableRow>
											 
										)}
									</TableBody>
								</Table>
							</TableContainer>
						</DialogContent>
					</Dialog>
					<ConfirmDialog
					    title="Approve Merchant?"
					    open={this.state.showConfirm}
					    setOpen={this.setShowConfirm}
					    onConfirm={this.approveMerchant}
					  >
					    Are you sure you want to approve this Merchant? 
					  </ConfirmDialog>
				</Paper>
			)
	}
}
export default withStyles(useStyles)(MerchantOnboardingComponent)