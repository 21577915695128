import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ApiService from './ApiService';
import AddMerchant from './AddMerchantForm';
import AddTerminalForm from './AddTerminalForm';
import EditTerminalForm from './EditTerminalForm';
import AddMerchantUrl from './AddMerchantUrlComponent';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import SearchIcon from '@material-ui/icons/Search';
import Tooltip from '@material-ui/core/Tooltip';
import ClearIcon from '@material-ui/icons/Clear';
import Divider from '@material-ui/core/Divider';
import MaterialTable from 'material-table';
import DateFnsUtils from '@date-io/date-fns';
import {subMonths,subDays, format} from 'date-fns';
import { forwardRef } from 'react';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import ChargeForm from './EntityChargeConfigForm';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Switch from '@material-ui/core/Switch';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = theme => ({
  button: {
    margin: theme.spacing(1),
  },

  detailhead: {
	 fontSize: 12,
  },
  searchbar: {
	margin: theme.spacing(2),
  },
  formControl: { 
    marginLeft: 20,
    minWidth: 120,
    maxWidth: 300,
  },
  buttonProgress: {
    color: theme.palette.secondary.main,
    position: 'absolute',
	marginTop: 8,
    marginLeft: -65,
  },
 accordHeader: {
	backgroundColor: '#f4e8fc',
	fontWeight: theme.typography.fontWeightMedium,
 },
});

class MerchantManagementComponent extends Component {
	
	constructor(props) {
        super(props)
        this.state = {
            merchantId: null,
			selectedMerchant: null,
			selectedTerminal: null,
			failed: false,
			success: false,
			responsemessage: null,
			merchants: [],
			terminals: [],
			addMerchantPanelVisible: false,
			addTerminalVisible: false,
			loading: false,
			terminalPanelVisible: false,
			addMerchantUrlPanelVisible: false,
			fraudVisible: false,
			enableFraud: false,
		}
		this.handleChange = this.handleChange.bind(this);
		this.reloadData = this.reloadData.bind(this);
		this.showAddMerchantDialogue = this.showAddMerchantDialogue.bind(this);
		this.hideAddMerchantDialogue = this.hideAddMerchantDialogue.bind(this);
		this.showTerminals = this.showTerminals.bind(this);
		this.hideAddTerminal = this.hideAddTerminal.bind(this);
		this.showAddTerminalDialogue = this.showAddTerminalDialogue.bind(this);
		this.showEditLimitDialogue = this.showEditLimitDialogue.bind(this);
		this.fetchAllMerchants = this.fetchAllMerchants.bind(this);
		this.migrateUser = this.migrateUser.bind(this);
		this.afterTerminalChange = this.afterTerminalChange.bind(this);
		this.showAddMerchantUrlPanel = this.showAddMerchantUrlPanel.bind(this);
		this.showFraudManageDialogue = this.showFraudManageDialogue.bind(this);
		this.hideFraudPanel = this.hideFraudPanel.bind(this);
		this.reloadData();
	}

	handleChange(event) {
        this.setState(
            {
                [event.target.name]
                    : event.target.value
            }
        )
    }
	reloadData() {
		this.fetchAllMerchants();
	} 
	showAddMerchantDialogue() {
		this.setState({addMerchantPanelVisible: true});
	}
	showAddTerminalDialogue() {
		this.setState({addTerminalVisible: true});
	}
	showFraudManageDialogue() {
		this.setState({fraudVisible: true});
	}
	hideFraudPanel() {
		this.setState({fraudVisible: false});
	}
	hideAddMerchantDialogue() {
		this.setState({addMerchantPanelVisible: false,terminalPanelVisible: false, addMerchantUrlPanelVisible: false});
	} 
	showEditLimitDialogue(data) {
		this.setState({selectedTerminal: data, terminalPanelVisible: true})
	}
	showAddMerchantUrlPanel() {		 
		this.setState({addMerchantUrlPanelVisible: true});
	}
	hideAddTerminal() {
		this.setState({addTerminalVisible: false,terminalPanelVisible: false});
	}
	fetchAllMerchants(){
		ApiService.fetchAllMerchants()
			.then((res) => {
				this.setState({merchants: res.data})
			});		
	}
	showTerminals() {
		ApiService.fetchMerchantTerminalsByMerchantId(this.state.selectedMerchant.id)
			.then((res)=> {
				this.setState({terminals: res.data,terminalPanelVisible: false})	
			}).catch((e)=> {
				
			});
	}
	afterTerminalChange(success, message) {		 
	    this.setState({success: success, responsemessage: message})
		this.showTerminals();
	}
	
	migrateUser() {
		this.setState({loading: true});
		ApiService.migrateUser(this.state.selectedMerchant.id)
			.then((res)=>{
				if(res.data.status == "OK")
					this.setState({success: true, responsemessage: "User Migrated successfully"})
				else
					this.setState({success: false, failed: true, responsemessage: res.data.responseMessage})
			})
			this.setState({loading: false});
	}
	tableIcons = {
	    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
	    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
	    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
	    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
	    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
	    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
	    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
	    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
	    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
	    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
	    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
	    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
	    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
	};
	
	render(){
		const { classes } = this.props; 
		return (
			<React.Fragment>
				<Paper style={{margin: '1em', padding: '1em'}}>
					   <Typography component="h2" variant="h6" color="primary" gutterBottom>
					      Merchant management
					   </Typography>
					<Divider light className={classes.searchbar}/>
					{this.state.failed &&  <Alert severity="error" elevation="0"> {this.state.responsemessage}</Alert> }
					{this.state.success && <Alert severity="success" elevation="0">{this.state.responsemessage}</Alert>}
					&nbsp;
					<Grid container  spacing={1}>
						<Grid>
						   <FormControl className={classes.formControl}>
							  <InputLabel id="merchantSelectLabel">Merchants</InputLabel>
							  <Select value={this.state.selectedMerchant}  MenuProps={this.MenuProps}
							  		onChange={this.handleChange} name="selectedMerchant"
							  		labelId="merchantSelectLabel" id="merchantTerminalSelect"
			 					>
								<MenuItem key="" value="">&nbsp; </MenuItem>	
								 {	this.state.merchants && this.state.merchants.map((m) =>
								  	 <MenuItem key={m.id} value={m} dense>{m.merchantName + "-" + m.code}</MenuItem>
								)}
							  </Select>
						 </FormControl>
				 	 	</Grid>
						{ this.state.selectedMerchant && 
						<Grid>
							<Table size="small" aria-label="a dense table">
						        <TableHead>
						          <TableRow>
						            <TableCell>Merchant id </TableCell>
						            <TableCell>Username</TableCell>						          
						            <TableCell>Email</TableCell>
						            <TableCell>Website</TableCell>
						          </TableRow>
						        </TableHead>
						        <TableBody>
									<TableRow key='2'>
              							<TableCell>{this.state.selectedMerchant.id} </TableCell>
							            <TableCell>{this.state.selectedMerchant.username}</TableCell>
							            <TableCell>{this.state.selectedMerchant.contactEmail}</TableCell>
							            <TableCell>{this.state.selectedMerchant.website}</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</Grid>}
					</Grid>
					<Grid justify="center" alignItems="center" style={{marginTop: '2em'}} container xs={12}>
				  	  	 <Grid item xs={3}>
							 <Button
						        variant="contained"
						        color="primary"
						        size="small"
						        className={classes.button}
								onClick={this.showTerminals}
						      >
						      Show Terminals
						    </Button>
		 					{this.state.loading && <CircularProgress size={25} className={classes.buttonProgress} />}
					    </Grid>
	 					<Grid item xs={3}>
							 <Button
						        variant="contained"
						        color="primary"
						        size="small"
						        className={classes.button}
								onClick={this.showAddMerchantUrlPanel}
						      >
						      Merchant URL/IP
						    </Button> 
					    </Grid>
						{/*<Grid item xs={4}>
							 <Button
						        variant="contained"
						        color="primary"
						        size="small"
						        className={classes.button}
								onClick={this.showAddMerchantDialogue}
						      >
						        Add Merchant
						    </Button>
		 					{this.state.loading && <CircularProgress size={25} className={classes.buttonProgress} />}
					    </Grid>*/}
						{/*<Grid item xs={4}>
							 <Button
						        variant="contained"
						        color="primary"
						        size="small"
						        className={classes.button}
								onClick={this.migrateUser}
						      >
						        Migrate user
						    </Button>
		 					{this.state.loading && <CircularProgress size={25} className={classes.buttonProgress} />}
					    </Grid>*/}
					</Grid>
					
					<MaterialTable
					  style={{margin: '1em 0 0 0'}}
				      title="Merchant Terminals" 
					  icons={this.tableIcons}
				      columns={[ 
				        { field: 'terminalId', title: 'Id',render: rowData => <>
							<Tooltip title={rowData.terminalId} interactive>
								 <InfoOutlinedIcon/>
							</Tooltip>
							</>
						},
						{ field: 'acquirer.name', title: 'Acquirer'},
				        { field: 'acquirerAccount.paymentMode.mode' , title: 'Pay mode'},
						{ field: 'acquirerAccount.cardType.type',title: 'Card type'},
						{ field: 'currency' , title: 'Currency'},
						{ field: 'settlementCurrency' , title: 'Settlement curr'},
						{ field: 'accountPoolId' , title: 'Pool'},
						{ field: 'isActive',title: 'Active'},					
				      ]}
				      data={this.state.terminals}
					  actions={[
						{
							icon: (props)=> <Button variant="contained" color="primary" size="small" className={classes.button} startIcon={<AddBox />}>Add Terminal</Button>,
							tooltip: 'Add Terminal',
							isFreeAction: true,
							onClick: (event) => this.showAddTerminalDialogue(),
						},
						{
							icon: (props)=> <Button variant="contained" color="primary" size="small" className={classes.button} startIcon={<EditIcon />}>Limits</Button>,
							tooltip: 'Edit limits' ,
							isFreeAction: false,
							onClick: (event, rowdata) => this.showEditLimitDialogue(rowdata),
						},
						{
							icon: (props)=> <Button variant="contained" color="primary" size="small" className={classes.button} startIcon={<EditIcon />}>Fraud & Risk</Button>,
							tooltip: 'Fraud and Risk management' ,
							isFreeAction: false,
							onClick: (event, rowdata) => this.showFraudManageDialogue(rowdata),
						}	
					  ]}
					  options={{
						search:false,
				        exportButton: false,
						pageSize: 15,
						pageSizeOptions: [15,30,45],
						padding: 'dense',
						showEmptyDataSourceMessage: true,
						selection: true,
						emptyRowsWhenPaging: false,
				      }}				     
				    />

					<Dialog open={this.state.addMerchantPanelVisible} onClose={this.hideAddMerchantDialogue}>
						<DialogTitle id="customized-dialog-title" onClose={this.hideAddMerchantDialogue}>
				          Add Merchant
				        </DialogTitle>
						<DialogContent>
							 <AddMerchant reloadMerchants={this.fetchAllMerchants}/>
						</DialogContent>
					</Dialog>
					
					<Dialog open={this.state.addMerchantUrlPanelVisible} onClose={this.hideAddMerchantDialogue}  maxWidth="md"
>
						<DialogTitle id="customized-dialog-url" onClose={this.hideAddMerchantDialogue}>
				          Add Merchant Front URL and IP
				        </DialogTitle>
						<DialogContent>
							 <AddMerchantUrl merchantId={this.state.selectedMerchant && this.state.selectedMerchant.id} reloadMerchants={this.fetchAllMerchants}/>
						</DialogContent>
					</Dialog>
					
					<Dialog open={this.state.addTerminalVisible} onClose={this.hideAddTerminal}>
						<DialogTitle id="customized-dialog-mapterminal" onClose={this.hideAddTerminal}>
				          Add terminal
				        </DialogTitle>
						<DialogContent>
							 <AddTerminalForm merchantId={this.state.selectedMerchant && this.state.selectedMerchant.id} reloadTerminals={this.showTerminals}/>
						</DialogContent>
					</Dialog>
					
					<Dialog open={this.state.terminalPanelVisible} onClose={this.hideAddTerminal}>
						<DialogTitle id="customized-dialog-mapterminal" onClose={this.hideAddTerminal}>
				          Terminal Limits
				        </DialogTitle>
						<DialogContent>
							<EditTerminalForm terminal={this.state.selectedTerminal} reloadTerminals={this.afterTerminalChange}/>
						</DialogContent>
					</Dialog>
					<Dialog open={this.state.fraudVisible} onClose={this.hideFraudPanel}>
						<DialogTitle id="fnr" onClose={this.hideFraudPanel}>
				         <Typography style={{fontWeight: 'bold'}} variant="subtitle1"> Fraud and Risk settings</Typography>
				        </DialogTitle>
						<DialogContent style={{paddingBottom: '2em'}}>
							 <Grid container justify="flex-end" alignItems="center" style={{paddingBottom: '2em'}}>
								<Grid xs={4}>
									Enable fraud checks
								</Grid>
								<Grid xs={8}>
									<Switch defaultChecked onChange={this.handleChange} name="enableFraud"></Switch>
								</Grid>
							 </Grid>
						     
							<Accordion>
								<AccordionSummary className={classes.accordHeader} expandIcon={<ExpandMoreIcon />}>Database Verifications</AccordionSummary>
								<AccordionDetails>
									<Grid container  justify="flex-end" alignItems="center">
										<Grid xs={6}>
											<Typography style={{fontWeight: 'bold'}} variant="subtitle2">Rule Name</Typography>
										</Grid>
										<Grid xs={2}>
											<Typography style={{fontWeight: 'bold'}} variant="subtitle2">Provider</Typography>
										</Grid>
										<Grid xs={4}>
											<Typography style={{fontWeight: 'bold'}} variant="subtitle2">Status</Typography>
										</Grid>
										<Grid xs={6}>
											Whitelist Database
										</Grid>
										<Grid xs={2}>
											AcuityTec
										</Grid>
										<Grid xs={4}>
											<Switch defaultChecked onChange={this.handleChange} name="whitelistDatabase"></Switch>
										</Grid>
										<Grid xs={6}>
											Negative Database
										</Grid>
										<Grid xs={2}>
											AcuityTec
										</Grid>
										<Grid xs={4}>
											<Switch defaultChecked onChange={this.handleChange} name="negativeDatabase"></Switch>
										</Grid>
										<Grid xs={6}>
											Declined reason Database
										</Grid>
										<Grid xs={2}>
											AcuityTec
										</Grid>
										<Grid xs={4}>
											<Switch defaultChecked onChange={this.handleChange} name="declinedDatabase"></Switch>
										</Grid>
										<Grid xs={6}>
											High risk Database
										</Grid>
										<Grid xs={2}>
											AcuityTec
										</Grid>
										<Grid xs={4}>
											<Switch defaultChecked onChange={this.handleChange} name="highRiskDatabase"></Switch>
										</Grid>
									 </Grid>
								
								</AccordionDetails>
							</Accordion>
							<Accordion>
								<AccordionSummary className={classes.accordHeader} expandIcon={<ExpandMoreIcon />}>Data source verifications</AccordionSummary>
								<AccordionDetails>
									<Grid container  justify="flex-end" alignItems="center">
										<Grid xs={6}>
											<Typography style={{fontWeight: 'bold'}} variant="subtitle2">Rule Name</Typography>
										</Grid>
										<Grid xs={2}>
											<Typography style={{fontWeight: 'bold'}} variant="subtitle2">Provider</Typography>
										</Grid>
										<Grid xs={4}>
											<Typography style={{fontWeight: 'bold'}} variant="subtitle2">Status</Typography>
										</Grid>
										<Grid xs={6}>
											Geo-location verification
										</Grid>
										<Grid xs={2}>
											AcuityTec
										</Grid>
										<Grid xs={4}>
											<Switch defaultChecked onChange={this.handleChange} name="geoLocationVerification"></Switch>
										</Grid>
										<Grid xs={6}>
											Devide Id and Fingerprint
										</Grid>
										<Grid xs={2}>
											AcuityTec
										</Grid>
										<Grid xs={4}>
											<Switch defaultChecked onChange={this.handleChange} name="deviceFingerprint"></Switch>
										</Grid>
										<Grid xs={6}>
											Email verification
										</Grid>
										<Grid xs={2}>
											AcuityTec
										</Grid>
										<Grid xs={4}>
											<Switch defaultChecked onChange={this.handleChange} name="emailVerification"></Switch>
										</Grid>
										<Grid xs={6}>
											Bin database
										</Grid>
										<Grid xs={2}>
											AcuityTec
										</Grid>
										<Grid xs={4}>
											<Switch defaultChecked onChange={this.handleChange} name="binDatabase"></Switch>
										</Grid>
									 </Grid>
								
								</AccordionDetails>
							</Accordion>	
							<Accordion>
								<AccordionSummary className={classes.accordHeader} expandIcon={<ExpandMoreIcon />}>Profile based verification</AccordionSummary>
								<AccordionDetails>
									<Grid container justify="flex-end" alignItems="center">
										<Grid xs={6}>
											<Typography style={{fontWeight: 'bold'}} variant="subtitle2">Rule Name</Typography>
										</Grid>
										<Grid xs={2}>
											<Typography style={{fontWeight: 'bold'}} variant="subtitle2">Provider</Typography>
										</Grid>
										<Grid xs={4}>
											<Typography style={{fontWeight: 'bold'}} variant="subtitle2">Status</Typography>
										</Grid>
										
										<Grid xs={6}>
											Address verification
										</Grid>
										<Grid xs={2}>
											AcuityTec
										</Grid>
										<Grid xs={4}>
											<Switch defaultChecked onChange={this.handleChange} name="addressVerify"></Switch>
										</Grid>
										<Grid xs={6}>
											Email / Domain verification
										</Grid>
										<Grid xs={2}>
											AcuityTec
										</Grid>
										<Grid xs={4}>
											<Switch defaultChecked onChange={this.handleChange} name="emailVerify"></Switch>
										</Grid>
										<Grid xs={6}>
											Phone ID verification
										</Grid>
										<Grid xs={2}>
											AcuityTec
										</Grid>
										<Grid xs={4}>
											<Switch defaultChecked onChange={this.handleChange} name="phoneVerify"></Switch>
										</Grid>
										<Grid xs={6}>
											AML Watchlist OFAC
										</Grid>
										<Grid xs={2}>
											AcuityTec
										</Grid>
										<Grid xs={4}>
											<Switch defaultChecked onChange={this.handleChange} name="amlOfac"></Switch>
										</Grid>
										<Grid xs={6}>
											AML Watchlist UN
										</Grid>
										<Grid xs={2}>
											AcuityTec
										</Grid>
										<Grid xs={4}>
											<Switch defaultChecked onChange={this.handleChange} name="amlUN"></Switch>
										</Grid>
										<Grid xs={6}>
											AML Watchlist EU
										</Grid>
										<Grid xs={2}>
											AcuityTec
										</Grid>
										<Grid xs={4}>
											<Switch defaultChecked onChange={this.handleChange} name="amlEU"></Switch>
										</Grid>
										<Grid xs={6}>
											AML Watchlist DFAT
										</Grid>
										<Grid xs={2}>
											AcuityTec
										</Grid>
										<Grid xs={4}>
											<Switch defaultChecked onChange={this.handleChange} name="amlDFAT"></Switch>
										</Grid>
										<Grid xs={6}>
											AML Watchlist PEPS
										</Grid>
										<Grid xs={2}>
											AcuityTec
										</Grid>
										<Grid xs={4}>
											<Switch defaultChecked onChange={this.handleChange} name="amlPEP"></Switch>
										</Grid>
										<Grid xs={6}>
											AML Watchlist HM Treasury
										</Grid>
										<Grid xs={2}>
											AcuityTec
										</Grid>
										<Grid xs={4}>
											<Switch defaultChecked onChange={this.handleChange} name="amlHMT"></Switch>
										</Grid>
										<Grid xs={6}>
											ID Verification
										</Grid>
										<Grid xs={2}>
											SumSub
										</Grid>
										<Grid xs={4}>
											<Switch onChange={this.handleChange} name="idVerify"></Switch>
										</Grid>
										<Grid xs={6}>
											Document verification
										</Grid>
										<Grid xs={2}>
											SumSub
										</Grid>
										<Grid xs={4}>
											<Switch onChange={this.handleChange} name="idDocVerify"></Switch>
										</Grid>
									 </Grid>
								
								</AccordionDetails>
							</Accordion>	
							<Accordion>
								<AccordionSummary className={classes.accordHeader} expandIcon={<ExpandMoreIcon />}>Transactional based data verification</AccordionSummary>
								<AccordionDetails>
									<Grid container justify="flex-end"  alignItems="center">
										<Grid xs={6}>
											<Typography style={{fontWeight: 'bold'}} variant="subtitle2">Rule Name</Typography>
										</Grid>
										<Grid xs={2}>
											<Typography style={{fontWeight: 'bold'}} variant="subtitle2">Provider</Typography>
										</Grid>
										<Grid xs={4}>
											<Typography style={{fontWeight: 'bold'}} variant="subtitle2">Status</Typography>
										</Grid>
										<Grid xs={6}>
											Liveness verification
										</Grid>
										<Grid xs={2}>
											SumSub
										</Grid>
										<Grid xs={4}>
											<Switch onChange={this.handleChange} name="liveness"></Switch>
										</Grid>
										<Grid xs={6}>
											Phone verify/ 4 digit pin auth
										</Grid>
										<Grid xs={2}>
											SumSub
										</Grid>
										<Grid xs={4}>
											<Switch onChange={this.handleChange} name="otpPhoneVerify"></Switch>
										</Grid>
										<Grid xs={6}>
											Traffic rules engine monitoring
										</Grid>
										<Grid xs={2}>
											AcuityTec
										</Grid>
										<Grid xs={4}>
											<Switch defaultChecked onChange={this.handleChange} name="trafficRules"></Switch>
										</Grid> 
									 </Grid> 
								</AccordionDetails>
							</Accordion>	
						</DialogContent>
					</Dialog>
				</Paper>
			</React.Fragment>
		);
	}
}
export default withStyles(useStyles)(MerchantManagementComponent);