import React, { Component, Fragment } from 'react'
import { Link, withRouter } from 'react-router-dom'
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeIcon from '@material-ui/icons/Home';
import ReceiptIcon from '@material-ui/icons/Receipt';
import MerchantIcon from '@material-ui/icons/SupervisedUserCircle';
//import RuleIcon from '@material-ui/icons/Rule';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import WorkIcon from '@material-ui/icons/Work';
import DashboardIcon from '@material-ui/icons/Dashboard';
import LayersIcon from '@material-ui/icons/SyncProblem';
import Avatar from '@material-ui/core/Avatar';
import ApiService from './ApiService';
import List from '@material-ui/core/List';
 import ChbkIcon from '@material-ui/icons/SettingsBackupRestore';
import RepeatIcon from '@material-ui/icons/Repeat';
import AppRegistrationIcon from '@material-ui/icons/HowToReg'


class MenuComponent extends Component {

	constructor(props) {
		 super(props)
		 this.state = {
			menuItems : [],
		}
	 
	}
	
	componentDidMount() {
		ApiService.fetchMenu().then((res) => {
			//alert("re.data" + res.data);
			this.setState({menuItems: res.data});
		});
	}
 	 
    render() {
        const isUserLoggedIn = ApiService.isUserLoggedIn();	
	 
        return (
              <List>
					{ this.state.menuItems && this.state.menuItems.map((mi) =>
					  	 <ListItem button component={Link} to={mi.link}>
					      <ListItemIcon>
							{ mi.feature.feature == 'HOME' &&
								<HomeIcon />	
							}
							{ mi.feature.feature == 'ONBOARDING' &&
								 <AppRegistrationIcon />	
							}
							{ mi.feature.feature == 'DASHBOARD' &&
								<DashboardIcon />	
							}
							{ mi.feature.feature == 'USERS' &&
								<PeopleAltIcon />
							}
							{ mi.feature.feature == 'ACQUIRER' &&
								<AccountBalanceIcon />
							}								
							{ mi.feature.feature == 'AFFILIATE' &&
								<WorkIcon />	
							}
							{ mi.feature.feature == 'MERCHANT' &&
								<MerchantIcon />
							}
							{ mi.feature.feature == 'RULE' &&
								<MerchantIcon />
							}
							{ mi.feature.feature == 'WIRE' &&
								<ReceiptIcon />
							}
							{ mi.feature.feature == 'TRANSACTIONS' &&
								<Avatar src="combo_chart.svg" variant="square" style={{width: 30, height: 30,}}/>	
							}
							{ mi.feature.feature == 'SUMMARY' &&
								 <FormatListBulletedIcon/>	
							}
							{ mi.feature.feature == 'STATUSUPLOAD' &&
								<LayersIcon />	
							}
							{ mi.feature.feature == 'CARDWHITELIST' &&
								 <CreditCardIcon />	
							}
							{ mi.feature.feature == 'CHARGEBACK' &&
								 <ChbkIcon />	
							}
							{ mi.feature.feature == 'ROLLRESERVE' &&
								 <RepeatIcon />	
							}							
					      </ListItemIcon>
					      <ListItemText primary={mi.text} />
					    </ListItem>
					)}
				</List>
            
        )
    }
}

export default (MenuComponent)
