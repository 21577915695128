import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {Divider, Typography, Paper} from '@material-ui/core';

const useStyles = theme => ({
 
  searchbar: {
	margin: theme.spacing(2),
  },
  
});

class Home extends Component {

	constructor(props) {
		 super(props);
	 
	}
	
	 
 	 
    render() {	
		 const { classes } = this.props; 
        return (             
            
				<Paper style={{margin: '1em', padding: '1em'}}>
					   <Typography component="h2" variant="h6" color="primary" gutterBottom>
					      Home
					   </Typography>
					<Divider light className={classes.searchbar}/>
					<Typography variant="subtitle1"> Welcome to Monitox payment gateway admin control panel !!</Typography>
					
					
				</Paper>
        )
    }
}

export default withStyles(useStyles)(Home)
