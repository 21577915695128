import React, { Component } from 'react';
import {Container, Card,CardContent,CardHeader,Divider,Grid, InputLabel, Select, Typography, MenuItem,Button, Box,FormControl , Input} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles'; 
import ApiService from './ApiService';
import Alert from '@material-ui/lab/Alert';

const useStyles = theme => ({
  topCard: {
	marginTop: theme.spacing(1),
	minHeight: '100vh',
},
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
	marginBottom: 10,
  },

});

class AddAcquirerAccountForm extends Component {
	 constructor(props) {
        	super(props);
	 		this.state = {
				acquirerAccountId: this.props.account? this.props.account.acquirerAccountId : null,
				acquirerId: props.acquirerId,
				mid:  this.props.account? this.props.account.mid : null,
				accountNumber:  props.account? props.account.accountNumber : null,
				settlementCurrency:  props.account? props.account.settlementCurrency : null,
				signkey:  props.account? props.account.signkey : null,
				userName:  props.account? props.account.userName : null,
				secret:  props.account? props.account.secret : null,
				accountPoolId: props.account? props.account.accountPoolId: null,
				billingDescriptor: props.account? props.account.billingDescriptor:null,
				dailyNotionalLimit: props.account? props.account.dailyNotionalLimit:null,
				cardTypes: props.cardTypes,
				paymentModes: props.paymentModes,
				failed: false,
				success: false,
				responseMessage: null,
				paymentMode: null,
				cardType: null,
				paymentModeId: props.account && props.account.paymentMode? props.account.paymentMode.id : null,
				cardTypeId: props.account && props.account.cardType ? props.account.cardType.id : null,
			}
			this.handleInputChange = this.handleInputChange.bind(this);
			this.handleSubmit = this.handleSubmit.bind(this);
			
			
		}
		handleInputChange(event) {
	        const target = event.target;
	        const inputName = target.name;        
	        const inputValue = target.value;
	        this.setState({
	            [inputName] : inputValue
	        });        
    	}
		
		handleSubmit() { 
			//get Card from Id 
			this.state.cardTypes.map( card => {
				if(this.state.cardTypeId != null && card.id == this.state.cardTypeId) {
					this.state.cardType = card;
					return;
				}
			})
			this.state.paymentModes.map( pm => {
				if(this.state.paymentModeId != null && pm.id == this.state.paymentModeId) {
					this.state.paymentMode = pm;
					return;
				}
			})
			var request = Object.assign({}, this.state);	
	 
			ApiService.addAmendAcquirerAccount(request).then((res) => {
				if(res.data.status == 'OK'){
					this.props.hide();
					this.setState({failed: false, success: true, responseMessage: res.data.responseMessage})
				}
				else
					this.setState({failed: true, success: false, responseMessage: res.data.responseMessage })
			}).catch(e => {
				this.setState({failed: true, responseMessage: "Error"})
			})
			
		}
	render() {
	const { classes } = this.props;
		return (
			 	 
					<form >
						{this.state.failed &&  <Alert severity="error" elevation="0"> {this.state.responseMessage}</Alert> }
						{this.state.success && <Alert severity="success" elevation="0">{this.state.responseMessage}</Alert>}
						<FormControl fullWidth margin="dense">
		                    <Input name="mid"  placeholder="MID" 
		                        value={this.state.mid} onChange={this.handleInputChange} required/> 
						</FormControl>
						 <FormControl fullWidth margin="dense">
		                    <Input name="accountNumber"  placeholder="Account number" 
		                        value={this.state.accountNumber} onChange={this.handleInputChange} required/> 
						</FormControl>
					    <FormControl fullWidth margin="dense">
		                    <Input name="settlementCurrency"  placeholder="Settlement Currency" 
		                        value={this.state.settlementCurrency} onChange={this.handleInputChange}/> 
						</FormControl>
						<FormControl fullWidth margin="dense">
		                    <Input name="signkey"  placeholder="Signkey" 
		                        value={this.state.signkey} onChange={this.handleInputChange} required/> 
						</FormControl>
						<FormControl fullWidth margin="dense">
		                    <Input name="userName"  placeholder="Username" 
		                        value={this.state.userName} onChange={this.handleInputChange}/> 
						</FormControl>
						<FormControl fullWidth margin="dense">
		                    <Input name="secret"  placeholder="Secret" 
		                        value={this.state.secret} onChange={this.handleInputChange}/> 
						</FormControl>
						<FormControl fullWidth margin="dense">
		                    <InputLabel id="paymentModeLabel">Payment mode</InputLabel>
							  <Select value={this.state.paymentModeId} 
							  		onChange={this.handleInputChange} name="paymentModeId"
							  		labelId="paymentModeLabel" id="paymentModeIdSelect"
			 					>
								<MenuItem key="" value="">&nbsp; </MenuItem>	
								 {	this.state.paymentModes && this.state.paymentModes.map((pm) =>
								  	 <MenuItem key={pm.id} value={pm.id} dense> {pm.type} </MenuItem>
								)}
							  </Select>
						</FormControl>
						<FormControl fullWidth margin="dense">
		                    <InputLabel id="cardTypeLabel">Card Type</InputLabel>
							  <Select value={this.state.cardTypeId} 
							  		onChange={this.handleInputChange} name="cardTypeId"
							  		labelId="cardTypeLabel" id="cardTypeLabelSelect"
			 					>
								<MenuItem key="" value="">&nbsp; </MenuItem>	
								 {	this.state.cardTypes && this.state.cardTypes.map((ct) =>
								  	 <MenuItem key={ct.id} value={ct.id} dense> {ct.type} </MenuItem>
								)}
							  </Select>
						</FormControl>
						<FormControl fullWidth margin="dense">
		                    <Input name="accountPoolId"  placeholder="Account Pool" 
		                        value={this.state.accountPoolId} onChange={this.handleInputChange}/> 
						</FormControl>
						<FormControl fullWidth margin="dense">
		                    <Input name="billingDescriptor"  placeholder="Billing Descriptor" 
		                        value={this.state.billingDescriptor} onChange={this.handleInputChange}/> 
						</FormControl>
						<FormControl fullWidth margin="dense">
		                    <Input name="dailyNotionalLimit"  placeholder="Daily Limit" 
		                        value={this.state.dailyNotionalLimit} onChange={this.handleInputChange}/> 
						</FormControl>
						<Box align="center">  
							<Button onClick={this.handleSubmit} variant="contained" color="primary" >Save </Button>
						</Box> 
					</form>
				 
		);
	}
}
export default withStyles(useStyles)(AddAcquirerAccountForm)