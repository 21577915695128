import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ApiService from './ApiService';
import AddMerchant from './AddMerchantForm';
import AddAcquirerAccountForm from './AddAcquirerAccountForm';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import SearchIcon from '@material-ui/icons/Search';
import Tooltip from '@material-ui/core/Tooltip';
import ClearIcon from '@material-ui/icons/Clear';
import Divider from '@material-ui/core/Divider';
import MaterialTable from 'material-table';
import DateFnsUtils from '@date-io/date-fns';
import {subMonths,subDays, format} from 'date-fns';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import ChargeForm from './EntityChargeConfigForm';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { forwardRef } from 'react';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = theme => ({
  button: {
    margin: theme.spacing(1),
  },

  detailhead: {
	 fontSize: 12,
  },
  searchbar: {
	margin: theme.spacing(2),
  },
  formControl: { 
    marginLeft: 20,
    minWidth: 120,
    maxWidth: 300,
  },
  buttonProgress: {
    color: theme.palette.secondary.main,
    position: 'absolute',
	marginTop: 8,
    marginLeft: -65,
  },
});

class AffiliateManagementComponent extends Component {
	constructor(props) {
        super(props)
        this.state = { 
			failed: false,
			success: false,
			responsemessage: null,
			acquirers: [],
			accounts: [],
			acquirer: [],
			account: null,
			addAcquirerPanelVisible: false,
			addAccountPanelVisible: false,
			cardTypes: [],
			paymentModes: [],
			
		}
		this.handleChange = this.handleChange.bind(this);
		this.reloadData = this.reloadData.bind(this);
		this.showAddAcquirerDialogue = this.showAddAcquirerDialogue.bind(this);
		this.hideAddAcquirerDialogue = this.hideAddAcquirerDialogue.bind(this);
		this.showAccountPanel = this.showAccountPanel.bind(this);
		this.hideccountPanel= this.hideccountPanel.bind(this);
		this.fetchAllAcquirers = this.fetchAllAcquirers.bind(this);
		this.fetchAccounts = this.fetchAccounts.bind(this);
		this.postAdd = this.postAdd.bind(this);
		this.reloadData();
	}

	handleChange(event) {
        this.setState(
            {
                [event.target.name]
                    : event.target.value
            }
        )
    }
	 
	reloadData() {
		this.fetchAllAcquirers();
		
	}
	componentDidMount() {
			ApiService.fetchAllCardType().then(res => {
				this.setState({cardTypes: res.data})		
			});
			
			ApiService.fetchAllPaymentMode().then(res => {
				this.setState({paymentModes: res.data})	
			}); 
		}
	fetchAllAcquirers() {
		ApiService.fetchAllAcquirers()
			.then((res)=> {
				this.setState({acquirers: res.data})	
			}).catch((e)=> {
				
			});
	}
	fetchAccounts() {
		if(this.state.acquirer == null) return;
		
		ApiService.fetchAcquirerAccountsByAcquirer(this.state.acquirer.acquirerId)
			.then((res)=> {
				this.setState({accounts: res.data})	
			}).catch((e)=> {
				
			});
	}
	showAddAcquirerDialogue() {
		this.setState({addAcquirerPanelVisible: true});
	}
	hideAddAcquirerDialogue() {
		this.setState({addAcquirerPanelVisible: false});
	}
	showAccountPanel() {
		this.setState({addAccountPanelVisible: true});
	}
	hideccountPanel() {
		this.setState({addAccountPanelVisible: false});
		this.setState({account: null});
	}
	amendAccount(data) {
		data.map( d => {
			this.setState({account: d});
		})
		this.showAccountPanel();
	}
	postAdd() {
		//this.hideccountPanel();
		this.fetchAccounts();
	}
	tableIcons = {
		    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
		    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
		    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
		    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
		    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
		    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
		    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
		    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
		    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
		    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
		    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
		    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
		    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
		    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
		    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
		    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
		    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
		};
	render(){
		const { classes } = this.props; 
		return (
			<React.Fragment>
				<Paper style={{margin: '1em', padding: '1em'}}>
					   <Typography component="h2" variant="h6" color="primary" gutterBottom>
					      Affiliate management
					   </Typography>
					<Divider light className={classes.searchbar}/>
					{this.state.failed &&  <Alert severity="error" elevation="0"> {this.state.responsemessage}</Alert> }
					{this.state.success && <Alert severity="success" elevation="0">{this.state.responsemessage}</Alert>}
					<Grid container spacing={1}>
						<Grid item>
							<FormControl className={classes.formControl}>
							  <InputLabel id="acqSelectLabel">Affiliates</InputLabel>
							  <Select value={this.state.acquirer}  MenuProps={this.MenuProps}
							  		onChange={this.handleChange} name="acquirer"
							  		labelId="acqSelectLabel" id="acqSelect"
			 					>
								<MenuItem key="" value="">&nbsp; </MenuItem>	
								 {	this.state.acquirers && this.state.acquirers.map((a) =>
								  	 <MenuItem key={a.acquirerId} value={a} dense>{a.name + "-" + a.number}</MenuItem>
								)}
							  </Select>
						 </FormControl>
						</Grid>
						{ this.state.acquirer && 
						<Grid>
							<Table size="small" aria-label="a dense table">
						        <TableHead>
						          <TableRow>
						            <TableCell>Affiliate id </TableCell>
						            <TableCell>Code</TableCell>						          
						            <TableCell>Name</TableCell>
						            <TableCell>Number</TableCell>
						          </TableRow>
						        </TableHead>
						        <TableBody>
									<TableRow key='2'>
              							<TableCell>{this.state.acquirer.acquirerId} </TableCell>
							            <TableCell>{this.state.acquirer.code}</TableCell>
							            <TableCell>{this.state.acquirer.name}</TableCell>
							            <TableCell>{this.state.acquirer.number}</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</Grid>}
					</Grid>
					<Box align="center">
						 <Button
						        variant="contained"
						        color="primary"
						        size="small"
						        className={classes.button}
								onClick={this.fetchAccounts}
						      >
						      Show Accounts
						    </Button>
		 					{this.state.loading && <CircularProgress size={25} className={classes.buttonProgress} />}
					</Box>
					
					
					<MaterialTable
					  style={{margin: '1em 0 0 0'}}
				      title="Affiliate accounts" 
					  icons={this.tableIcons}
				      columns={[ 
				        { field: 'acquirerAccountId',numeric: false, title: 'Id',render: rowData => <>
							<Tooltip title={rowData.acquirerAccountId} interactive>
								 <InfoOutlinedIcon/>
							</Tooltip>
							</>
						},				
						{ field: 'mid' , numeric: false, title: 'MID'},
						{ field: 'accountNumber' ,numeric: false, title: 'Account no'},	 
				        { field: 'paymentMode.mode' ,numeric: false, title: 'Pay mode'},
						{ field: 'cardType.type',numeric: false, title: 'Card type'},
						{ field: 'settlementCurrency' ,numeric: false, title: 'Currency'},
						{ field: 'userName' ,numeric: false,  title: 'Username'},			
				      ]}
				      data={this.state.accounts}
					  actions={[
						{
							icon: (props)=> <Button variant="contained" color="primary" size="small" className={classes.button} startIcon={<AddBox />}>Add Account</Button>,
							tooltip: 'Add Account',
							isFreeAction: true,
							onClick: (event) => this.showAccountPanel(),
						},
						{
							tooltip: 'Amend selected Account',
							icon: (props)=> <EditIcon {...props} />,
							onClick: (evt, data) => this.amendAccount(data),
						}
					  ]}
					  options={{
						search:false,
				        exportButton: false,
						pageSize: 30,
						pageSizeOptions: [15,30,45],
						padding: 'dense',
						showEmptyDataSourceMessage: true,
						selection: true,
						emptyRowsWhenPaging: false,
						tableLayout: 'fixed',
				      }}				     
				    />
				</Paper>
				<Dialog open={this.state.addAccountPanelVisible} onClose={this.hideccountPanel}>
					<DialogTitle id="customized-dialog-title" onClose={this.hideccountPanel}>
			         { this.state.account == null ? "Add charge" : "Amend charge"} 
			        </DialogTitle>
					<DialogContent>
						<AddAcquirerAccountForm  account={this.state.account} acquirerId={this.state.acquirer.acquirerId} hide={this.postAdd} cardTypes={this.state.cardTypes} paymentModes={this.state.paymentModes}/>
					</DialogContent>
				</Dialog>
			</React.Fragment>
			);
	}
}
export default withStyles(useStyles)(AffiliateManagementComponent);