import React, { Component, Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles';
import { Link, withRouter } from 'react-router-dom'
import ApiService from './ApiService';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box'; 
import MaterialTable, { MTableToolbar } from 'material-table';
import Divider from '@material-ui/core/Divider';
import TableIcons from './TableLogoComponent';
import DeleteIcon from '@material-ui/icons/DeleteForever';


const useStyles = theme => ({
 
  searchbar: {
	margin: theme.spacing(2),
  },
  formControl: {
    minWidth: 200,
  },

});

class AddMerchantUrlComponent extends Component {

	constructor(props) {
		 super(props)
		 this.state = {
			merchantUrls : [],
			merchantIps : [],
			failed: false,
			success: false,
			responsemessage: null,
			url: null,
			ip: null,
		}
	 	this.handleChange = this.handleChange.bind(this);
		this.reloadData = this.reloadData.bind(this);
		this.addMerchantUrl = this.addMerchantUrl.bind(this);
		this.addMerchantIp = this.addMerchantIp.bind(this);
	}
	handleChange(event) {
        this.setState(
            {
                [event.target.name]
                    : event.target.value, critChanged: true,
            }
        )
    }
	componentDidMount() {
		this.reloadData();
	}
	reloadData() {
			ApiService.fetchMerchantUrls(this.props.merchantId).then((res) => {
			//alert("re.data" + res.data);
			this.setState({merchantUrls: res.data});
		});
		ApiService.fetchMerchantIps(this.props.merchantId).then((res) => {
			//alert("re.data" + res.data);
			this.setState({merchantIps: res.data});
		});
	}
	
	
	addMerchantUrl() {
		ApiService.addMerchantUrl(this.props.merchantId, this.state.url).then((res) => {
			if(res.data.status == "OK") {
				this.reloadData();
				this.setState({success: true, failed: false, responsemessage: res.data.responseMessage})
			} else {
				 this.setState({failed: true, success: false, responsemessage: res.data.responseMessage})
			}
		}).catch((e) => {
			 this.setState({failed: true, success: false, responsemessage: e})
		});;
	}
	
	addMerchantIp() {
		ApiService.addMerchantIp(this.props.merchantId, this.state.ip).then((res) => {
			if(res.data.status == "OK") {
				this.reloadData();
				this.setState({success: true, failed: false, responsemessage: res.data.responseMessage})
			} else {
				 this.setState({failed: true, success: false, responsemessage: res.data.responseMessage})
			}
		}).catch((e) => {
			 this.setState({failed: true, success: false, responsemessage: e})
	    });
	}
 	
	removeMerchantUrl(data) {
		ApiService.removeMerchantUrl(this.props.merchantId, data.id).then((res) => {
			this.reloadData();
			this.setState({success: true, failed: false, responsemessage: res.data.responseMessage})
		}).catch((e) => {
			 this.setState({failed: true, success: false, responsemessage: e})
	    });;
	}
	
	removeMerchantIp(data) {
		ApiService.removeMerchantIp(this.props.merchantId, data.id).then((res) => {
			this.reloadData();
			this.setState({success: true, failed: false, responsemessage: res.data.responseMessage})
		}).catch((e) => {
			 this.setState({failed: true, success: false, responsemessage: e})
	    });;
	}

    render() {
        const { classes } = this.props; 
	 
        return (
             <Paper>
				{this.state.failed &&  <Alert severity="error" elevation="0"> {this.state.responsemessage}</Alert> }
				{this.state.success &&  <Alert severity="success" elevation="0"> {this.state.responsemessage}</Alert> }
				<Grid xs={12} container spacing={1}>
				<Grid xs={6} item >
					<TextField id="url" name="url" label="Merchant URL" value={this.state.url} onChange={this.handleChange} />
					<Button variant="contained" className={classes.searchbar} color="primary" size="small" onClick={this.addMerchantUrl}>
						Add Url
					</Button>
				 </Grid>
					
				<Grid xs={6} item>
					<TextField id="ip" name="ip" label="Merchant IP" value={this.state.ip} onChange={this.handleChange} />
					<Button variant="contained" className={classes.searchbar} color="primary" size="small" onClick={this.addMerchantIp}>
						Add IP
					</Button>
				 </Grid>
				</Grid>
				<Divider/>
				<Grid xs={12} container spacing={1}>
				<Grid xs={6} item >
				<MaterialTable 
				      title="Merchant URL" 
					  icons={TableIcons.icons}
				      columns={[ 
				        { field: 'url',numeric: false, title: 'Url'},			
							 
				      ]}
				      data={this.state.merchantUrls}
					  actions={[						
						{
							tooltip: 'Delete url',
							icon: (props)=> <DeleteIcon {...props} />,
							onClick: (evt, data) => this.removeMerchantUrl(data),
						}
					  ]}
					  options={{
						search:false,
				        exportButton: false,
						pageSize: 10,
						pageSizeOptions: [10,20],
						padding: 'dense',
						showEmptyDataSourceMessage: true,
						emptyRowsWhenPaging: false,
				      }}				     
				    />
				 </Grid>
					
				<Grid xs={6} item>
					<MaterialTable 
				      title="Merchant IPS" 
					  icons={TableIcons.icons}
				      columns={[ 
				        { field: 'ip',numeric: false, title: 'IP'},			
							 
				      ]}
				      data={this.state.merchantIps}
					  actions={[						
						{
							tooltip: 'Delete Ip',
							icon: (props)=> <DeleteIcon {...props} />,
							onClick: (evt, data) => this.removeMerchantIp(data),
						}
					  ]}
					  options={{
						search:false,
				        exportButton: false,
						pageSize: 10,
						pageSizeOptions: [10,20],
						padding: 'dense',
						showEmptyDataSourceMessage: true,
						emptyRowsWhenPaging: false,
				      }}				     
				    />
				 </Grid>
				</Grid>
			 </Paper>
        )
    }
}

export default withStyles(useStyles)(AddMerchantUrlComponent)
