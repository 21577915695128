import React, { Component } from 'react'
import { Formik } from 'formik'
import { object, ref, string } from 'yup'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import ApiService from './ApiService';
import Spinner from './Spinner'
import Alert from '@material-ui/lab/Alert';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {MuiPickersUtilsProvider,DatePicker} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
const useStyles = theme => ({
  button: {
    margin: theme.spacing(1),
  },
  tablewraptext: {
	textOverflow: 'ellipsis', 
    overflow: 'hidden', 
    whiteSpace: 'nowrap',
  },
  small: {
    width: theme.spacing(7),
    height: theme.spacing(4),
  },
  medium: {
    width: '45px',
    height: '15px', 
  },
 searchbar: {
	margin: theme.spacing(2),
  },
 form: {

  width: 60,
},
formWrapper: {
  margin: theme.spacing(4),
  width: 200,
},
formError: {
  width: 200,
},
});

class AddTerminalForm extends Component {
    constructor(props) {
        super(props)
		 this.state = {
		    txnSuccess: false,
			responsemessage: null,
			hasTxnFailed: false,
			acquirerAccounts: [],
			selectedTerminal: props.terminal[0],
		}
		this.reloadData = this.reloadData.bind(this);
		 
		 
	}
	reloadData() {
		 
	}
 
		 
	 
	_handleSubmit = ({
    acquirerAccountId,
    currency,
    settlementCurrency,
	priority,
	limitChecks,
	isTestTerminal,
	isCurrencyExchange,
	cardWhitelist,
	urlCheck,
	ipCheck,
	uevevnTicketCheck,
	billingDescriptor,
	minTxnNotionalLimit,
	txnNotionalLimit,
	dailyNotionalLimit,
	weeklyNotionalLimit,
	monthlyNotionalLimit,
	dailyTxnVolumeLimit,
	monthlyTxnVolumeLimit,
	masterTerminal,
	accountPoolId,
    setSubmitting,
    resetForm,
  }) => {
	const formData = {
		'terminalId': this.state.selectedTerminal.terminalId,
		'merchantId': this.props.merchantId,
		'acquirerAccountId': this.state.selectedTerminal.acquirerAccount.acquirerAccountId,
		'acquirerId': this.state.selectedTerminal.acquirerAccount.acquirerId,
		'currency': currency,
	    'settlementCurrency': settlementCurrency,
		'priority': priority,
		'limitChecks': limitChecks==true?'Y':'N',
		'isTestTerminal': isTestTerminal==true?'Y':'N',
		'isCurrencyExchange' : isCurrencyExchange==true?'Y':'N',
		'cardWhitelist' : cardWhitelist==true?'Y':'N',
		'urlCheck' : urlCheck==true?'Y':'N',
		'ipCheck' : ipCheck==true?'Y':'N',
		'uevevnTicketCheck' : uevevnTicketCheck==true?'Y':'N',
		'billingDescriptor': billingDescriptor,	
		'minTxnNotionalLimit': minTxnNotionalLimit,
		'txnNotionalLimit': txnNotionalLimit,
		'dailyNotionalLimit' : dailyNotionalLimit,
		'weeklyNotionalLimit': weeklyNotionalLimit,
		'monthlyNotionalLimit': monthlyNotionalLimit,
		'dailyTxnVolumeLimit': dailyTxnVolumeLimit,
		'monthlyTxnVolumeLimit': monthlyTxnVolumeLimit,
		'masterTerminal': masterTerminal==true? 'Y':'N',
		'accountPoolId' : accountPoolId,
	}
	 ApiService.editTerminal(formData)
		.then((res) => {
				if(res.data.status == 'OK') {
					 setSubmitting(false)					 
					this.props.reloadTerminals(true, res.data.responseMessage );
				} else {
					this.setState(() => ({
				        txnSuccess: false,
						hasTxnFailed: true,
						responsemessage: res.data.responseMessage,
				      }))
					setSubmitting(false)
				}
            
		}).catch((e) => {
			this.setState(() => ({
				        txnSuccess: false,
						hasTxnFailed: true,
						responsemessage: 'Error',
				      }))
			setSubmitting(false)
	})
  	}
	render() {
		const { classes } = this.props;
	    return (
		<>
		 	{this.state.hasTxnFailed &&  <Alert severity="error" elevation="0"> {this.state.responsemessage} </Alert> }
			{this.state.txnSuccess &&  <Alert severity="success" elevation="0"> Terminal added successfully ! </Alert> }
			<Grid container xs={12} spacing={1} alignItems="center" justify="space-evenly">
				<Grid  item xs={8}>
					<Formik
				        initialValues={{
						   	acquirerAccountId: this.state.selectedTerminal.acquirerAccount.acquirerAccountId,
						    currency: this.state.selectedTerminal.currency,
						    settlementCurrency: this.state.selectedTerminal.settlementCurrency,
							priority: this.state.selectedTerminal.priority,
							limitChecks: this.state.selectedTerminal.limitChecks === 'Y'? true: false,
							isTestTerminal: this.state.selectedTerminal.isTestTerminal === 'Y'? true: false,
							isCurrencyExchange: this.state.selectedTerminal.isCurrencyExchange === 'Y'? true: false,
							cardWhitelist: this.state.selectedTerminal.terminalDetail && this.state.selectedTerminal.terminalDetail.isCardWhiteList === 'Y'? true: false,
							urlCheck: this.state.selectedTerminal.terminalDetail && this.state.selectedTerminal.terminalDetail.urlCheck === 'Y'? true: false,
							ipCheck: this.state.selectedTerminal.terminalDetail && this.state.selectedTerminal.terminalDetail.ipCheck === 'Y'? true: false,
							uevevnTicketCheck: this.state.selectedTerminal.terminalDetail && this.state.selectedTerminal.terminalDetail.uevevnTicketCheck === 'Y'? true: false,
							billingDescriptor: this.state.selectedTerminal.billingDescriptor,
							minTxnNotionalLimit: this.state.selectedTerminal.terminalDetail != null? this.state.selectedTerminal.terminalDetail.minTxnNotionalLimit : null,
							txnNotionalLimit: this.state.selectedTerminal.terminalDetail != null? this.state.selectedTerminal.terminalDetail.txnNotionalLimit : null,
							dailyNotionalLimit: this.state.selectedTerminal.terminalDetail != null?this.state.selectedTerminal.terminalDetail.dailyNotionalLimit: null,
							weeklyNotionalLimit: this.state.selectedTerminal.terminalDetail != null?this.state.selectedTerminal.terminalDetail.weeklyNotionalLimit: null,
							monthlyNotionalLimit: this.state.selectedTerminal.terminalDetail != null? this.state.selectedTerminal.terminalDetail.monthlyNotionalLimit: null,
							dailyTxnVolumeLimit: this.state.selectedTerminal.terminalDetail != null? this.state.selectedTerminal.terminalDetail.dailyTxnVolumeLimit: null,
							monthlyTxnVolumeLimit: this.state.selectedTerminal.terminalDetail != null? this.state.selectedTerminal.terminalDetail.monthlyTxnVolumeLimit: null,
							masterTerminal: this.state.selectedTerminal.masterTerminal,
							accountPoolId: this.state.selectedTerminal.accountPoolId,
				        }}
				        validationSchema={object().shape({
						  acquirerAccountId: string().required('Acquirer account is requirred'),
				          currency: string().required('Processing currency is required'),
				          settlementCurrency: string().required('Settlement currency is required'),
				          minTxnNotionalLimit: string().required('Min Tx amount is required'),
						  txnNotionalLimit: string().required('Max Tx amount is required'),
				        })}
				        onSubmit={(
				          { acquirerAccountId,currency,settlementCurrency,priority,limitChecks,isTestTerminal,isCurrencyExchange,cardWhitelist,urlCheck,ipCheck,uevevnTicketCheck,billingDescriptor,minTxnNotionalLimit,txnNotionalLimit,dailyNotionalLimit,weeklyNotionalLimit,monthlyNotionalLimit,dailyTxnVolumeLimit,monthlyTxnVolumeLimit,masterTerminal,accountPoolId,},
				          { setSubmitting, resetForm }
				        ) =>
				          this._handleSubmit({
				            acquirerAccountId,
							currency,
							settlementCurrency,
							priority,
							limitChecks,
							isTestTerminal,
							isCurrencyExchange,
							cardWhitelist,
							urlCheck,
							ipCheck,
							uevevnTicketCheck,
							billingDescriptor,
							minTxnNotionalLimit,
							txnNotionalLimit,
							dailyNotionalLimit,
							weeklyNotionalLimit,
							monthlyNotionalLimit,
							dailyTxnVolumeLimit,
							monthlyTxnVolumeLimit,
							masterTerminal,
							accountPoolId,
				            setSubmitting,
				            resetForm,
				          })
				        }

						render={props => {
					          const {
					            values,
					            touched,
					            errors,
					            handleChange,
					            handleBlur,
					            handleSubmit,
					            isValid,
					            isSubmitting,
					          } = props
					          return isSubmitting ? (
					            <Spinner />
					          ) : (
									<form onSubmit={handleSubmit} >									
										<FormControl margin="dense" fullWidth size="small">
										    <InputLabel id="merchantTerminalcurrency">Processing Currency</InputLabel>
											<Input 
							                    id="currency"
							                    name="currency"
												placeholder="Processing currency"
							                    value={values.currency}
							                    onChange={handleChange}
							                    onBlur={handleBlur}
												labelId="merchantTerminalcurrency"
							                    error={Boolean(touched.currency && errors.currency)}
											/>
										</FormControl>
										<FormControl margin="dense" fullWidth size="small">	
											<InputLabel id="merchantTerminalSettcurrency">Settlement Currency</InputLabel>
											<Input 
							                    id="settlementCurrency"
							                    name="settlementCurrency"
												placeholder="Settlement currency"
							                    value={values.settlementCurrency}
							                    onChange={handleChange}
							                    onBlur={handleBlur}
												labelId="merchantTerminalSettcurrency"
							                    error={Boolean(touched.settlementCurrency && errors.settlementCurrency)}
											/>
											</FormControl>
										<FormControl margin="dense" fullWidth size="small">
											<InputLabel id="merchantTerminalpriority">Priority</InputLabel>
											<Input 
							                    id="priority"
							                    name="priority"
												placeholder="Priority"
							                    value={values.priority}
							                    onChange={handleChange}
							                    onBlur={handleBlur}
												labelId="merchantTerminalpriority"
							                    error={Boolean(touched.priority && errors.priority)}
											/>
											</FormControl>
										<FormControl margin="dense" fullWidth size="small">
											<InputLabel id="merchantTerminaldesc">Billing descriptor</InputLabel>
											<Input 
							                    id="billingDescriptor"
							                    name="billingDescriptor"
												placeholder="Default billing descriptor"
							                    value={values.billingDescriptor}
							                    onChange={handleChange}
							                    onBlur={handleBlur}
												labelId="merchantTerminaldesc"
							                    error={Boolean(touched.billingDescriptor && errors.billingDescriptor)}
											/>
											</FormControl>
										<FormControl margin="dense" fullWidth size="small">
										   <InputLabel id="minTxnNotionalLimitDesc">Min Transaction amount</InputLabel>
											<Input 
							                    id="minTxnNotionalLimit"
							                    name="minTxnNotionalLimit"
												placeholder="Min Transaction amount"
							                    value={values.minTxnNotionalLimit}
							                    onChange={handleChange}
							                    onBlur={handleBlur}
												labelId="minTxnNotionalLimitDesc"
							                    error={Boolean(touched.minTxnNotionalLimit && errors.minTxnNotionalLimit)}
											/>
											</FormControl>
										<FormControl margin="dense" fullWidth size="small">
										   <InputLabel id="maxTxnNotionalLimitDesc">Max Transaction amount</InputLabel>
											<Input 
							                    id="txnNotionalLimit"
							                    name="txnNotionalLimit"
												placeholder="Max Transaction amount"
							                    value={values.txnNotionalLimit}
							                    onChange={handleChange}
							                    onBlur={handleBlur}
							                    error={Boolean(touched.txnNotionalLimit && errors.txnNotionalLimit)}
											/>
											</FormControl>
										<FormControl margin="dense" fullWidth size="small">
										    <InputLabel id="dailyNotionalLimitDesc">Daily max transaction notional</InputLabel>
											<Input 
							                    id="dailyNotionalLimit"
							                    name="dailyNotionalLimit"
												placeholder="Daily max transaction notional"
							                    value={values.dailyNotionalLimit}
							                    onChange={handleChange}
							                    onBlur={handleBlur}
							                    error={Boolean(touched.dailyNotionalLimit && errors.dailyNotionalLimit)}
											/>
											</FormControl>
										<FormControl margin="dense" fullWidth size="small">
										    <InputLabel id="weeklyNotionalLimitDesc">Weekly Max transaction notional</InputLabel>
											<Input 
							                    id="weeklyNotionalLimit"
							                    name="weeklyNotionalLimit"
												placeholder="Weekly Max transaction notional"
							                    value={values.weeklyNotionalLimit}
							                    onChange={handleChange}
							                    onBlur={handleBlur}
							                    error={Boolean(touched.weeklyNotionalLimit && errors.weeklyNotionalLimit)}
											/>
											</FormControl>
										<FormControl margin="dense" fullWidth size="small">
											<InputLabel id="monthlyNotionalLimitDesc">Monthly max transaction notional</InputLabel>
											<Input 
							                    id="monthlyNotionalLimit"
							                    name="monthlyNotionalLimit"
												placeholder="Monthly max transaction notional"
							                    value={values.monthlyNotionalLimit}
							                    onChange={handleChange}
							                    onBlur={handleBlur}
							                    error={Boolean(touched.monthlyNotionalLimit && errors.monthlyNotionalLimit)}
											/>
											</FormControl>
										<FormControl margin="dense" fullWidth size="small">
										    <InputLabel id="dailyTxnVolumeLimitDesc">Daily max no of transaction</InputLabel>
											<Input 
							                    id="dailyTxnVolumeLimit"
							                    name="dailyTxnVolumeLimit"
												placeholder="Daily max no of transaction"
							                    value={values.dailyTxnVolumeLimit}
							                    onChange={handleChange}
							                    onBlur={handleBlur}
							                    error={Boolean(touched.dailyTxnVolumeLimit && errors.dailyTxnVolumeLimit)}
											/>
											</FormControl>
										<FormControl margin="dense" fullWidth size="small">
										    <InputLabel id="monthlyTxnVolumeLimitDesc">Monthly max no of transaction</InputLabel>
											<Input 
							                    id="monthlyTxnVolumeLimit"
							                    name="monthlyTxnVolumeLimit"
												placeholder="Monthly max no of transaction"
							                    value={values.monthlyTxnVolumeLimit}
							                    onChange={handleChange}
							                    onBlur={handleBlur}
							                    error={Boolean(touched.monthlyTxnVolumeLimit && errors.monthlyTxnVolumeLimit)}
											/>
											</FormControl>
										<FormControl margin="dense" fullWidth size="small">
								 			<FormControlLabel control={
												<Checkbox checked={values.masterTerminal} name="masterTerminal" id="masterTerminal" onChange={handleChange} onBlur={handleBlur} />
												} label ="Master Terminal" />
											</FormControl>
										<FormControl margin="dense" fullWidth size="small">
										    <InputLabel id="accountPoolIdLabel">Account Pool ID</InputLabel>
											<Input 
							                    id="accountPoolId"
							                    name="accountPoolId"
												placeholder="Account Pool ID for master terminal"
							                    value={values.accountPoolId}
							                    onChange={handleChange}
							                    onBlur={handleBlur}
											/>
											</FormControl>
										<FormControl margin="dense" fullWidth size="small">
								 			<FormControlLabel control={
												<Checkbox checked={values.limitChecks} name="limitChecks" id="limitChecks" onChange={handleChange} onBlur={handleBlur} />
												} label ="Limit checks" />
											</FormControl>
										<FormControl margin="dense" fullWidth size="small">
								 			<FormControlLabel control={
												<Checkbox checked={values.cardWhitelist} name="cardWhitelist" id="cardWhitelist" onChange={handleChange} onBlur={handleBlur} />
												} label ="Card Whitelist" />
										</FormControl>
										<FormControl margin="dense" fullWidth size="small">
								 			<FormControlLabel control={
												<Checkbox checked={values.urlCheck} name="urlCheck" id="urlCheck" onChange={handleChange} onBlur={handleBlur} />
												} label ="URL Validation" />
										</FormControl>
										<FormControl margin="dense" fullWidth size="small">
								 			<FormControlLabel control={
												<Checkbox checked={values.ipCheck} name="ipCheck" id="ipCheck" onChange={handleChange} onBlur={handleBlur} />
												} label ="IP Validation" />
										</FormControl>
										<FormControl margin="dense" fullWidth size="small">
								 			<FormControlLabel control={
												<Checkbox checked={values.uevevnTicketCheck} name="uevevnTicketCheck" id="uevevnTicketCheck" onChange={handleChange} onBlur={handleBlur} />
												} label ="Un-even ticket size check" />
										</FormControl> 
										<FormControl margin="dense" fullWidth size="small">
											<FormControlLabel control={
												<Checkbox checked={values.isTestTerminal} name="isTestTerminal" id="isTestTerminal" onChange={handleChange} onBlur={handleBlur} />
												} label ="Is Test terminal" />
										</FormControl>
										<FormControl margin="dense" fullWidth size="small">
											<FormControlLabel control={
												<Checkbox checked={values.isCurrencyExchange} name="isCurrencyExchange" id="isCurrencyExchange" onChange={handleChange} onBlur={handleBlur} />
												} label ="Is Currency Conversion" />
										</FormControl>
										

										
											<Button
							                  type="submit"
							                  variant="contained"
							                  color="primary"
							                  disabled={Boolean(!isValid || isSubmitting)} fullWidth
											  style={{marginTop: '2em'}}
							                >
							                  {'Modify'}
							                </Button> 
									</form>
									 
								)
					 }}
					/>
				</Grid>
			</Grid>
		</>
		);
	}
}
export default withStyles(useStyles)(AddTerminalForm);