import React, { Component } from 'react';
import {Container, Card,CardContent,CardHeader,Divider,Grid, InputLabel, Select, Typography, MenuItem,Button, Box,FormControl , Input} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles'; 
import ApiService from './ApiService';
import Alert from '@material-ui/lab/Alert';

const useStyles = theme => ({
  topCard: {
	marginTop: theme.spacing(1),
	minHeight: '100vh',
},
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
	marginBottom: 10,
  },

});

class AddNewAcquirerForm extends Component {
	 constructor(props) {
        	super(props);
	 		this.state = {
				name:null,
                code:null,
                number:null,
                signkey:null,
                prodApiURL:null,
                testApiURL:null,
                prodRefundUrl:null,
                uatRefundUrl:null,
				failed: false,
				success: false,
				responseMessage: null,				
			}

			this.handleInputChange = this.handleInputChange.bind(this);
			this.handleSubmit = this.handleSubmit.bind(this);
						
		}
		handleInputChange(event) {
	        const target = event.target;
	        const inputName = target.name;        
	        const inputValue = target.value;
	        this.setState({
	            [inputName] : inputValue
	        });        
    	}
		
		handleSubmit() { 
		
			var request = Object.assign({}, this.state);	

			ApiService.addAcquirer(request).then((res) => {
				if(res.data.status == 'OK'){					
					this.setState({failed: false, success: true, responseMessage: res.data.responseMessage})
				}
				else
					this.setState({failed: true, success: false, responseMessage: res.data.responseMessage })
			}).catch(e => {
				this.setState({failed: true, responseMessage: "Error"})
			})
			
		}

	    render() {
	        const { classes } = this.props;
		    return (
			 	 
					<form >
						{this.state.failed &&  <Alert severity="error" elevation="0"> {this.state.responseMessage}</Alert> }
						{this.state.success && <Alert severity="success" elevation="0">{this.state.responseMessage}</Alert>}
						
                        <FormControl fullWidth margin="dense">
		                    <Input name="name"  placeholder="Name" 
		                        value={this.state.name} onChange={this.handleInputChange} required/> 
						</FormControl>

						 <FormControl fullWidth margin="dense">
		                    <Input name="code"  placeholder="Code" 
		                        value={this.state.code} onChange={this.handleInputChange} required/> 
						</FormControl>

					    <FormControl fullWidth margin="dense">
		                    <Input name="number"  placeholder="Number" 
		                        value={this.state.number} onChange={this.handleInputChange}/> 
						</FormControl>

						<FormControl fullWidth margin="dense">
		                    <Input name="signkey"  placeholder="Signkey" 
		                        value={this.state.signkey} onChange={this.handleInputChange} required/> 
						</FormControl>

						<FormControl fullWidth margin="dense">
		                    <Input name="prodApiURL"  placeholder="Prod Api Url" 
		                        value={this.state.prodApiURL} onChange={this.handleInputChange}/> 
						</FormControl>

						<FormControl fullWidth margin="dense">
		                    <Input name="testApiURL"  placeholder="Test Api Url" 
		                        value={this.state.testApiURL} onChange={this.handleInputChange}/> 
						</FormControl>						
					
						<FormControl fullWidth margin="dense">
		                    <Input name="prodRefundUrl"  placeholder="Prod Refund url" 
		                        value={this.state.prodRefundUrl} onChange={this.handleInputChange}/> 
						</FormControl>

						<FormControl fullWidth margin="dense">
		                    <Input name="uatRefundUrl"  placeholder="Uat Refund Url" 
		                        value={this.state.uatRefundUrl} onChange={this.handleInputChange}/> 
						</FormControl>
						
						<Box align="center">  
							<Button onClick={this.handleSubmit} variant="contained" color="primary">Add </Button>
						</Box> 
					</form>
				 
		);
	}
}
export default withStyles(useStyles)(AddNewAcquirerForm)