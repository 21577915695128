import React from 'react'
import "./App.css";
import { Bar, defaults } from 'react-chartjs-2'
 

const BarChart = (props) => {  
			const month = [];
			const monthTxn = [];
			if(props.monthSummary!= null )props.monthSummary.map((r) => { month.push(r.month); monthTxn.push(r.successfulTxnAmount)});
	
  return (
    
 	 
      <Bar
      
        data={{
         
          labels: month,
          datasets: [
            {
              
              label: 'Sales Chart',
              data: monthTxn,
              backgroundColor:  'rgb(99, 117, 98)',
              borderColor: 'black',
            },
             
          ],
        }}
        height={300}  
 		width={420}
        options={{
          maintainAspectRatio: false,
          scales: {
            xAxes: [{
              barThickness: 50,
              maxBarThickness: 50,
              width:20
            }],
            yAxes: [
              
              {
                
                ticks: {
                  
                  beginAtZero: true,
                },
              },
            ],
          },
          legend: {
            position: 'bottom',
            
            labels: {
              fontSize: 25,
            },
          },
        }}
      />
   
  )
}

export default BarChart